import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';

export const routes: Routes = [
  { path: 'login',
    component: LoginComponent },
];

/**
 * Module for login routing
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
