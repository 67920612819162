import { LatLng, Routing } from 'leaflet';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { HereAction, HereResult, HereRoute } from './here-result';
import * as PolyLine from './flexible-polyline';
import { LoggerService } from 'src/app/shared';


@Injectable()
export class HEREv8RoutingService implements Routing.IRouter {

  public constructor(@Inject(HttpClient) private readonly httpClient: HttpClient,
    @Inject(LoggerService) private readonly logger: LoggerService) {}

  // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
  public route(waypoints: Routing.Waypoint[],
    callback: (error?: Routing.IError, routes?: Routing.IRoute[]) => any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    context?: { },
    options?: Routing.RoutingOptions): void {
    if (options === undefined) {
      throw new Error('options are required');
    }

    const url = this.buildRouteUrl(waypoints);

    this.httpClient.get<any>(url).subscribe({
      next: (body: HereResult) => {
        if (body.notices) {
          body.notices.forEach((notice) => {
            this.logger.logError(notice as unknown as Error);
          });
        }
        const lrmRoutes = this.convertToLrmRoutes(body, waypoints);
        callback.call(context ?? callback, undefined, lrmRoutes); // todo: why?
      },
      error: (err: Error) => this.logger.logError(err),
    });
  }

  private convertToLrmRoutes(resBody: any,
    inputWayPoints: Routing.Waypoint[]): Routing.IRoute[] {
    if (!resBody?.routes) {
      return []; // no matches found
    }

    return resBody.routes.map((route: HereRoute) => ({
      name: '',
      summary: this.buildSummary(route),
      coordinates: this.buildCoordinates(route),
      waypoints: this.buildWaypoints(route),
      instructions: this.buildInstructions(route),
      inputWaypoints: inputWayPoints,
    }));
  }

  // eslint-disable-next-line max-lines-per-function
  private buildInstructions(route: HereRoute): Routing.IInstruction[] {
    const instructions: Routing.IInstruction[] = [];
    route.sections.forEach((section) => {
      section.actions.forEach((action) => {
        instructions.push({
          distance: action.length,
          time: action.duration,
          text: action.instruction,
          direction: action.direction,
          type: this.actionToInstructionType(action),
          modifier: this.actionToInstructionType(action),
          index: action.offset,
        } as Routing.IInstruction);
      });
    });

    return instructions;
  }

  private actionToInstructionType(action: HereAction): Instruction {
    switch (action.action) {
      case 'depart':
        return 'StartAt';
      case 'continue':
        return 'Straight';
      case 'turn':
      case 'exit':
      case 'ramp':
      case 'keep':
        return this.getDirectionMagnitude(action);
      case 'uTurn':
        return 'TurnAround';
      case 'arrive':
        return 'DestinationReached';
      case 'roundaboutEnter':
      case 'roundaboutPass':
      case 'roundaboutExit':
        return 'Roundabout';
      default:
        return action as any;
    }
  }

  private getDirectionMagnitude(action: HereAction): Instruction {
    if (action.direction === 'left') {
      switch (action.severity) {
        case 'light':
          return 'SlightLeft';
        case 'heavy':
          return 'SharpLeft';
        default:
          return 'Left';
      }
    } else if (action.direction === 'right') {
      switch (action.severity) {
        case 'light':
          return 'SlightRight';
        case 'heavy':
          return 'SharpRight';
        default:
          return 'Right';
      }
    } else {
      return 'Straight';
    }
  }

  private buildWaypoints(route: HereRoute): Routing.Waypoint[] {
    const waypoints: Routing.Waypoint[] = [];

    route.sections.forEach((section) => {
      const polyLine = PolyLine.decode(section.polyline).polyline;
      polyLine.forEach((coord) => {
        const latLng = new LatLng(coord[0], coord[1]);
        const wp = new Routing.Waypoint(latLng, '', {});
        waypoints.push(wp);
      });
    });

    return waypoints;
  }

  private buildCoordinates(route: HereRoute): LatLng[] {
    const coords: LatLng[] = [];
    route.sections.forEach((section) => {
      const polyLine = PolyLine.decode(section.polyline).polyline;
      polyLine.forEach((coord) => {
        const latLng = new LatLng(coord[0], coord[1]);
        coords.push(latLng);
      });
    });

    return coords;
  }

  private buildSummary(route: HereRoute): Routing.IRouteSummary {
    let totalDistance = 0;
    route.sections.forEach((x) => {
      totalDistance += x.summary.length;
    });

    let totalTime = 0;
    route.sections.forEach((x) => {
      totalTime += x.summary.duration;
    });
    return {
      totalDistance,
      totalTime,
    };
  }

  private buildRouteUrl(waypoints: Routing.Waypoint[]): string {
    const attrRoutingMode = 'fast';
    const attrTransportMode = 'car';
    const attrsManeuver = 'polyline,summary,travelSummary,actions,turnByTurnActions,instructions';
    // eslint-disable-next-line max-len
    let url = `https://router.hereapi.com/v8/routes?apiKey=${environment.hereMapsApiKey}&routingMode=${attrRoutingMode}&transportMode=${attrTransportMode}&return=${attrsManeuver}&alternatives=2`;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    waypoints?.forEach((waypoint, idx) => {
      if (idx === 0) {
        url += `&origin=${waypoint.latLng.lat},${waypoint.latLng.lng}`;
      } else if (idx === waypoints.length - 1) {
        url += `&destination=${waypoint.latLng.lat},${waypoint.latLng.lng}`;
      } else {
        url += `&via=${waypoint.latLng.lat},${waypoint.latLng.lng}`;
      }
    });

    return url;
  }

}

type Instruction = 'DestinationReached' | 'EnterAgainstAllowedDirection' | 'LeaveAgainstAllowedDirection' | 'Left' | 'Right' | 'Roundabout' | 'SharpLeft' | 'SharpRight' | 'SlightLeft' | 'SlightRight' | 'StartAt' | 'Straight' | 'TurnAround' | 'WaypointReached';
