import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
// import length from 'units-converter';

@Pipe({
  name: 'abbreviateNumber',
  pure: true,
})
export class AbbreviateNumberPipe implements PipeTransform {

  public transform(raw: number | string | null | undefined): string {
    if (raw === null || raw === undefined || isNaN(Number(raw))) {
      throw new Error('Invalid input.');
    }

    let output = raw.toString();

    // get values before . (eg 1000.10)
    const match = output.match('[^.]*');
    if (match !== null) {
      output = match[0];
    }

    // start formatting at 10000 or 5 digits
    if (output.length < 4) {
      return raw.toString();
    }

    switch (output.length) {
      case 1:
      case 2:
      case 3:
        return raw.toString();
      case 4:
        return `${output.substring(0, 1)}K`;
      case 5:
        return `${output.substring(0, 2)}K`;
      case 6:
        return `${output.substring(0, 3)}K`;
      case 7:
        return `${output.substring(0, 1)}M`;
      case 8:
        return `${output.substring(0, 2)}M`;
      case 9:
        return `${output.substring(0, 3)}M`;
      case 10:
        return `${output.substring(0, 1)}B`;
      case 11:
        return `${output.substring(0, 2)}B`;
      case 12:
        return `${output.substring(0, 3)}B`;
      default:
        return `${output.substring(0, output.length - 9)}B`;
    }
  }

}
