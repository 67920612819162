import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { Route } from '@angular/router';
import type { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

/**
 * Guard to make sure user is authenticated
 */
@Injectable()
export class CanLoadGuard {

  public constructor(@Inject(Router) private readonly router: Router,
    @Inject(AuthService) private readonly authService: AuthService) { }

  public canLoad(route: Route): Observable<boolean> {
    return this.authService.isDoneLoading$
      .pipe(filter(isDoneLoading => isDoneLoading))
      .pipe(switchMap(() => forkJoin({
        isLoggedIn: this.authService.isLoggedIn(),
        hasPermission: this.authService.userHasPermission(route.data?.permissions as string[] ?? []),
      })))
      .pipe(tap((check) => {
        if (!check.isLoggedIn) {
          const redir = this.router.getCurrentNavigation()?.extractedUrl.toString();

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/login'], {
            queryParams: { redirect: redir },
          });
        } else if (!check.hasPermission) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/'], {
            skipLocationChange: true,
          });
        }
      }))
      .pipe(map(check => check.hasPermission));
  }

}
