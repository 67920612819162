<mtx-header-full></mtx-header-full>
<div class="wrapper">
       <div id="user-form" class="container" style="margin-top: 52px;">

        <div class="row">

            <div class="col-lg-6 col-md-10 col-12 me-auto ms-auto">
                <!-- BANNER -->
                <div class="col-12 title-bar">{{ title }}
                </div>
                <div class="content">
                    <div class="row">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <mtx-alert-display [alerts]="messages"></mtx-alert-display>
                        </div>
                    </div>

                    <form [formGroup]="userForm" (ngSubmit)="save()" *ngIf="userForm"
                        [ngClass]="{ 'ng-submitted': userForm.submitted === true }">
                        <div class="row" style="padding-top:20px;">

                            <!-- USER DETAILS -->
                            <div class="col-12">
                                <div class="container text-end">

                                    <div *ngIf="userAuth" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            [translate]="'admin.users.atx-id'">
                                        </label>
                                        <div class="col-lg-6 col-12 text-start">
                                            {{userInfo.id}}
                                        </div>
                                    </div>

                                    <div *ngIf="userAuth && userProfile" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            [translate]="'admin.users.mtx-id'">
                                        </label>
                                        <div class="col-lg-6 col-12 text-start">
                                            {{userProfile.id}}
                                        </div>
                                    </div>

                                    <div *ngIf="userAuth" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            for="default_organization_id" [translate]="'admin.user-profile.default-org'">
                                        </label>
                                        <div class="col-lg-6 col-12">
                                            <select id="default_organization_id" type="text"
                                                class="form-select form-select-sm"
                                                formControlName="default_organization_id">
                                                <option value="" [translate]="'admin.user-profile.select'"></option>
                                                <option *ngFor="let opt of orgOptions" [value]="opt.id">{{opt.name}}
                                                </option>
                                            </select>
                                            <mtx-validation-messages
                                                [control]="userForm.controls.default_organization_id"
                                                [submitted]="userForm.submitted"></mtx-validation-messages>
                                        </div>
                                    </div>

                                    <div *ngIf="userAuth" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            for="default_timezone" [translate]="'admin.user-profile.timezone'">
                                        </label>
                                        <div class="col-lg-6 col-12">
                                            <select id="default_timezone" type="text"
                                                class="form-select form-select-sm"
                                                formControlName="default_timezone">
                                                <option *ngFor="let opt of timezoneOptions" [value]="opt">{{opt}}
                                                </option>
                                            </select>
                                            <mtx-validation-messages
                                                [control]="userForm.controls.default_timezone"
                                                [submitted]="userForm.submitted"></mtx-validation-messages>
                                        </div>
                                    </div>

                                    <div *ngIf="userAuth" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            for="locale" [translate]="'admin.user-profile.locale'">
                                        </label>
                                        <div class="col-lg-6 col-12">
                                            <select id="locale" type="text"
                                                class="form-select form-select-sm"
                                                formControlName="locale">
                                                <option *ngFor="let opt of localeOptions" [value]="opt">{{opt}}
                                                </option>
                                            </select>
                                            <mtx-validation-messages
                                                [control]="userForm.controls.locale"
                                                [submitted]="userForm.submitted"></mtx-validation-messages>
                                        </div>
                                    </div>

                                    <div *ngIf="userAuth" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            for="date_format" [translate]="'admin.user-profile.date-format'">
                                        </label>
                                        <div class="col-lg-6 col-12">
                                            <select id="date_format" type="text"
                                                class="form-select form-select-sm"
                                                formControlName="date_format">
                                                <option *ngFor="let opt of dateFormatOptions" [value]="opt">{{opt}}
                                                </option>
                                            </select>
                                            <mtx-validation-messages
                                                [control]="userForm.controls.date_format"
                                                [submitted]="userForm.submitted"></mtx-validation-messages>
                                        </div>
                                    </div>

                                    <div *ngIf="userAuth" class="row form-group">
                                        <label class="col-md-4 col-12 text-md-right  my-auto"
                                            for="use_24_hour_clock" [translate]="'admin.user-profile.time-format'">
                                        </label>
                                        <div class="col-lg-6 col-12">
                                            <select id="use_24_hour_clock" type="text"
                                                class="form-select form-select-sm" formControlName="use_24_hour_clock">
                                                <option [value]="false" [translate]="'admin.user-profile.12-hour'"></option>
                                                <option [value]="true" [translate]="'admin.user-profile.24-hour'"></option>
                                            </select>
                                            <mtx-validation-messages
                                                [control]="userForm.controls.use_24_hour_clock"
                                                [submitted]="userForm.submitted"></mtx-validation-messages>
                                        </div>
                                    </div>

                                    <div class="row" style="padding-bottom: 10px;">
                                        <div class="col-md-10 col-12 text-end">
                                            <input type="button" (click)="save()" value="{{ 'general.submit' | translate }}"
                                                class="btn btn-sm float-end" id="submit-btn" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>

                </div>
            </div>

        </div>
    </div>
</div>

<mtx-footer *ngIf="userAuth"></mtx-footer>
