/* eslint-disable no-undef */
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {

  public idle$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public wake$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private isIdle = false;

  private readonly idleAfterMinutes = 30;

  private readonly interactDelaySeconds = 10;

  private countDown?: NodeJS.Timeout;

  public constructor(@Inject(LocalStorageService) private readonly storageService: LocalStorageService) {
    // Setup events
    fromEvent(document, 'mousemove').pipe(debounceTime(this.interactDelaySeconds * 1000))
      .subscribe(() => this.onInteraction());
    fromEvent(document, 'touchstart').pipe(debounceTime(this.interactDelaySeconds * 1000))
      .subscribe(() => this.onInteraction());
    fromEvent(document, 'keydown').pipe(debounceTime(this.interactDelaySeconds * 1000))
      .subscribe(() => this.onInteraction());
  }

  private onInteraction(): void {
    // if user is idle and interacts, emit wake. users should be force logged out by the time this fires
    if (this.isIdle) {
      this.isIdle = false;
      this.wake$.next(true);
    }

    const lastActive = new Date().getTime();
    this.storageService.store('lastActive', lastActive);
    // clear existing idle timer
    clearTimeout(this.countDown);
    // restart idle timer,
    this.countDown = setTimeout(() => {
      this.isIdle = true;
      const lastActiveCheck = this.storageService.retrieve('lastActive');
      const timeout = new Date().getTime();

      if ((timeout - lastActiveCheck) / (60 * 1000) >= this.idleAfterMinutes) {
        this.idle$.next(true);
      }
    }, this.idleAfterMinutes * 60 * 1000);
  }

}
