import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'vehicleArrayNameJoin',
})
export class VehicleArrayNameJoinPipe implements PipeTransform {

  public transform(input: CondensedVehicleArray[], sep = ', '): string {
    const vehicleNames = input.map(a => a.name);
    return vehicleNames.join(sep);
  }

}

interface CondensedVehicleArray {
  id: string;
  name: string;
}
