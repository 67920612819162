import { Component, Inject, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component of modal for confirming/canceling actions
 */
@Component({
  selector: 'mtx-confirmation-modal',
  template: `
    <div id="confirmationModal">
      <div class="modal-header">
        <span
          class="modal-title pull-left"
          [translate]="'shared.are-you-sure'"
        ></span>
        <button
          type="button"
          class="btn-close pull-right"
          aria-label="Close"
          (click)="activeModal.dismiss('cancel')"
        >
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <span style="white-space:pre;">{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          id="btnCMConfirm"
          type="button"
          class="btn btn-sm btn-confirm"
          (click)="activeModal.close('ok')"
          [translate]="'general.ok'"
        ></button>
        <button
          id="btnCMCancel"
          type="button"
          class="btn btn-sm"
          (click)="activeModal.dismiss('cancel')"
          [translate]="'general.cancel'"
        ></button>
      </div>
    </div>
  `,
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {

  /** Message passed from element */
  @Input() public message?: string;

  public constructor(@Inject(NgbActiveModal) public activeModal: NgbActiveModal) {}

}
