/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import type { AbstractControl, ValidationErrors } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import type { HttpResponse } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import type { AlertMessage } from '..';
import type { RFCAlertMessage } from '../components/alert-display/rfc-alert-message';

/**
 * Service for logging errors and information
 */
@Injectable()
export class LoggerService {

  /**
   *
   * @param err Error
   */
  public logError(err: Error): void {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  /**
   * Takes API status code and displays message
   * @param err API error
   */
  // eslint-disable-next-line max-lines-per-function, max-statements
  public apiErrorsToDisplayErrors(err: HttpResponse<any> | any): AlertMessage[] {
    const results: AlertMessage[] = [];

    if (!err) {
      results.push({
        type: 'danger',
        msg: 'An unknown error occurred. Please try again.',
      });
      return results;
    }

    if (err instanceof HttpErrorResponse) {
      if (err.status === 500) {
        results.push({
          type: 'danger',
          msg: 'An error occurred. Please try again.',
        });
      } else if (err.status === 504 || err.status === 408) {
        results.push({ type: 'danger',
          msg: 'Request timed out' });
      } else if (err.status === 400) {
        const messages = err.error;
        if (messages && Array.isArray(messages)) {
          for (const message of messages) {
            results.push({ type: 'danger',
              msg: message });
          }
        }
      } else if (err.status === 404) {
        results.push({ type: 'danger',
          msg: 'Resource not found.' });
      } else if (err.status === 403) {
        results.push({
          type: 'danger',
          msg: 'You are not allowed to perform that action.',
        });
      } else if (err.status === 401) {
        results.push({
          type: 'danger',
          msg: 'Login has expired. Please log back in.',
        });
      } else if (err.status === 503) {
        results.push({
          type: 'danger',
          msg: 'MotoTrax is temporarily unavailble. Please try again in a few minutes.',
        });
      } else if (err.status === 0) {
        results.push({
          type: 'danger',
          msg: 'Unable to reach MotoTrax API. Ensure you have an active internet connection.',
        });
      } else {
        results.push({
          type: 'danger',
          msg: `Invalid response received [${err.status}]. Please try again.`,
        });
      }
    } else {
      const msg = err.message ?? err.toString();
      results.push({ type: 'danger',
        msg });
    }

    return results;
  }

  // eslint-disable-next-line max-lines-per-function, max-statements, @typescript-eslint/explicit-module-boundary-types
  public apiObjectErrorToDisplayErrors(err: any): AlertMessage[] | RFCAlertMessage[] {
    const results: AlertMessage[] | RFCAlertMessage[] = [];

    if (!err) {
      (results as AlertMessage[]).push({
        type: 'danger',
        msg: 'An unknown error occurred. Please try again.',
      });
      return results;
    }

    if (err instanceof HttpErrorResponse) {
      if (err.status === 500) {
        (results as AlertMessage[]).push({
          type: 'danger',
          msg: 'An error occurred. Please try again.',
        });
      } else if (err.status === 504 || err.status === 408) {
        (results as AlertMessage[]).push({ type: 'danger',
          msg: 'Request timed out' });
      } else if (err.status === 400) {
        // RFC error handling
        const messages: AlertMessage[] = [];
        const rfcError = err.error as RFCError;
        for (const prop in rfcError.errors) {
          if (prop) {
            // eslint-disable-next-line max-depth
            for (const message of rfcError.errors[prop]) {
              messages.push({ type: 'danger', msg: message });
            }
          }
        }
        (results as RFCAlertMessage[]).push({ title: rfcError.title, messages });
      } else if (err.status === 404) {
        (results as AlertMessage[]).push({ type: 'danger',
          msg: 'Resource not found.' });
      } else if (err.status === 403) {
        (results as AlertMessage[]).push({
          type: 'danger',
          msg: 'You are not allowed to perform that action.',
        });
      } else if (err.status === 401) {
        (results as AlertMessage[]).push({
          type: 'danger',
          msg: 'Login has expired. Please log back in.',
        });
      } else if (err.status === 503) {
        (results as AlertMessage[]).push({
          type: 'danger',
          msg: 'MotoTrax is temporarily unavailble. Please try again in a few minutes.',
        });
      } else if (err.status === 0) {
        (results as AlertMessage[]).push({
          type: 'danger',
          msg: 'Unable to reach MotoTrax API. Ensure you have an active internet connection.',
        });
      } else {
        (results as AlertMessage[]).push({
          type: 'danger',
          msg: `Invalid response received [${err.status}]. Please try again.`,
        });
      }
    } else {
      const msg = err.message ?? err.toString();
      (results as AlertMessage[]).push({ type: 'danger',
        msg });
    }

    return results;
  }

  /**
   * Takes formgroup and returns error messages for view depending on validation for fields
   * @param fg FormGroup
   * @returns Messages to display
   */
  public mapFormGroupToErrors(fg: UntypedFormGroup): AlertMessage[] {
    let messages: AlertMessage[] = [];

    // reads top level fg errors
    messages = messages.concat(this.readErrors(fg));

    // iterates through controls in fg
    for (const key in fg.controls) {
      if (!fg.controls[key]) {
        continue;
      }

      if (fg.controls[key] instanceof UntypedFormGroup) {
        messages = messages.concat(this.readErrors(fg.controls[key], key));
        messages = messages.concat(this.mapFormGroupToErrors(fg.controls[key] as UntypedFormGroup));
      } else {
        messages = messages.concat(this.readErrors(fg.controls[key], key));
      }
    }

    return messages;
  }

  // eslint-disable-next-line max-lines-per-function, max-statements
  private readErrors(control: AbstractControl, key?: string): AlertMessage[] {
    const messages: AlertMessage[] = [];
    let upperKey = key ? this.renameField(this.cleanName(key)) : 'Form';
    // If Id of a nested form group is required,
    if (upperKey.toLowerCase() === 'id' && control.parent !== control.root) {
      upperKey = this.getParentKeyName(control);
    }
    if (control.hasError('required')) {
      messages.push({ type: 'danger',
        msg: `${upperKey} is required.` });
    } else if (control.hasError('match')) {
      messages.push({ type: 'danger',
        msg: 'Passwords must match' });
    } else if (control.hasError('invalid date')) {
      messages.push({ type: 'danger',
        msg: `${upperKey} is invalid.` });
    } else if (control.hasError('custom')) {
      if (Array.isArray(control.errors?.custom)) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        control.errors?.custom.forEach((err: { msg: string }) => {
          messages.push({ type: 'danger', msg: err.msg });
        });
      } else {
        messages.push({ type: 'danger', msg: control.errors?.custom.msg });
      }
    } else if (control.hasError('pattern')) {
      messages.push({
        type: 'danger',
        msg: `${upperKey} does not meet the required format.`,
      });
    } else if (control.hasError('minlength')) {
      const minLength = (control.errors?.minlength as ValidationErrors).requiredLength;

      if (Array.isArray(control.value)) {
        messages.push({
          type: 'danger',
          msg: `${upperKey} selection must contain at least ${minLength} items.`,
        });
      } else {
        messages.push({
          type: 'danger',
          msg: `${upperKey} must have at least ${minLength} characters.`,
        });
      }
    } else if (
      control.hasError('maxlength')
    ) {
      const maxLength = (control.errors?.maxlength as ValidationErrors).requiredLength;

      if (Array.isArray(control.value)) {
        messages.push({
          type: 'danger',
          msg: `${upperKey} selection cannot contain more than ${maxLength} items.`,
        });
      } else {
        messages.push({
          type: 'danger',
          msg: `${upperKey} cannot exceed ${maxLength} characters.`,
        });
      }
    } else if (control.invalid && control instanceof UntypedFormControl) {
      // not applying to FG level, otherwise it will stack with FG control errors
      messages.push({
        type: 'danger',
        msg: `${upperKey} contains an invalid value`,
      });
    }

    return messages;
  }

  /**
   * Takes string and formats it to have uppercase letter at beginning and spaces instead of underscores
   * @param str String to be formatted
   * @returns New string
   */
  private cleanName(str: string): string {
    let name = str[0].toUpperCase() + str.slice(1);
    name = name.replace('_', ' ');
    return name;
  }

  private getParentKeyName(control: AbstractControl): string {
    const fg = control.parent;
    const fgParent = fg?.parent;
    let name = '';

    Object.keys(fgParent!.controls).forEach((key) => {
      const childControl = fgParent!.get(key);

      if (childControl !== fg) {
        return;
      }

      name = key.charAt(0).toUpperCase() + key.slice(1);
    });

    return name;
  }

  /**
   * Converts field names
   * @param name Takes string name
   * @returns New name
   */
  private renameField(name: string): string {
    switch (name) {
      case 'Organization':
        return 'Account';
      case 'Vehicle.id':
        return 'Vehicle';
      case 'Vehicle.group.id':
        return 'Group';
      case 'Route.id':
        return 'Route';
      case 'User.id':
        return 'User';
      case 'Driver.id':
        return 'Driver';
      default:
        return name;
    }
  }

}

interface RFCError {
  type: string;
  title: string;
  status: number;
  errors: Record<string, string[]>;
}
