/* eslint-disable max-len */
import type { PipeTransform } from '@angular/core';
import { Inject } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Route } from '@angular/router';
import type { Observable } from 'rxjs';
import { first, of } from 'rxjs';
import { AuthService } from 'src/app/auth.service';

@Pipe({ name: 'filterLinksByPermission' })
export class FilterLinksByPermissionPipe implements PipeTransform {

  public constructor(@Inject(AuthService) private readonly authService: AuthService) {}

  public transform(links: Route[]): Observable<{ accessableLinks: Route[]; disabledLinks: Route[] }> {
    const accessableLinks: Route[] = [];
    const disabledLinks: Route[] = [];
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    links.forEach((link) => {
      if (link.data?.permissions.length === 0 && !link.children) {
        // push link if no permissions and no children
        accessableLinks.push(link);
      } else if (link.children) {
        // if the link contains children, check permissions of childlinks and push appropriately. push link if at least one childlink
        const filteredChildRoutes: Route[] = [];
        link.children.forEach((childLink) => {
          this.authService.userHasPermission(childLink?.data?.permissions as string[]).pipe(first())
            .subscribe({
              next: (hasPermission) => {
                if (hasPermission) {
                  filteredChildRoutes.push(childLink);
                }
              },
            });
        });
        if (filteredChildRoutes.length > 0) {
          link.children = filteredChildRoutes;
          accessableLinks.push(link);
        } else {
          disabledLinks.push(link);
        }
      } else {
        // if permissions exist and no children, check permissions and push link
        this.authService.userHasPermission(link?.data?.permissions as string[]).pipe(first())
          .subscribe({
            next: (hasPermission) => {
              if (hasPermission) {
                accessableLinks.push(link);
              } else {
                disabledLinks.push(link);
              }
            },
          });
      }
    });

    return of({ accessableLinks, disabledLinks });
  }

}
