import { Inject, Injectable } from '@angular/core';
import 'leaflet-control-geocoder';
import type { Point } from 'geojson';
import type { Observable } from 'rxjs';
import { map, of } from 'rxjs';
import * as L from 'leaflet';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoggerService, ParamSerializer } from 'src/app/shared';
import type { GeocodingCallback, IGeocoder } from 'leaflet-control-geocoder/dist/geocoders';

@Injectable()
export class GeocoderService implements IGeocoder {

  public constructor(@Inject(HttpClient) private readonly httpClient: HttpClient,
    @Inject(ParamSerializer) private readonly paramSerializer: ParamSerializer,
    @Inject(LoggerService) private readonly logger: LoggerService) { }


  public actualGeocode(query: string): Observable<{ formatted_address: string; geometry: Point;
    bbox: { northEast: [number, number]; southWest: [number, number] }; }[]> {
    if (!query) {
      return of([]);
    }

    const reg = query.match(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);

    if (reg !== null) {
      const coords = reg[0].split(',');
      const lat = parseFloat(coords[0].trim());
      const lng = parseFloat(coords[1].trim());
      return of([
        {
          formatted_address: reg[0],
          bbox: { northEast: [lat + 0.000001, lng + 0.000001],
            southWest: [lat - 0.000001, lng - 0.000001] },
          geometry: {
            type: 'Point',
            coordinates: [
              lng,
              lat,
            ],
          },
        },
      ]);
    }


    const newQuery = query.replace(' ', '+');

    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${newQuery}&apiKey=${environment.hereMapsApiKey}`;

    return this.httpClient.get<HereGeocodeSearchResults>(url, { observe: 'body' }).pipe(map(res => res.items.map(item => ({
      formatted_address: item.title,
      bbox: { northEast: [item.mapView.north, item.mapView.east],
        southWest: [item.mapView.south, item.mapView.west] },
      geometry: {
        type: 'Point',
        coordinates: [
          item.position.lng,
          item.position.lat,
        ],
      },
    }))));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
  public geocode(query: string, cb: GeocodingCallback, context?: any): void {
    this.actualGeocode(query).subscribe(
      {
        next: x => cb(x.map(z => ({
          center: new L.LatLng(z.geometry.coordinates[1], z.geometry.coordinates[0]),
          bbox: new L.LatLngBounds(z.bbox.northEast, z.bbox.southWest),
          name: z.formatted_address,
        }))),
        error: err => this.logger.apiErrorsToDisplayErrors(err),
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
  public suggest?(query: string, cb: GeocodingCallback, context?: any): void {
    this.actualGeocode(query).subscribe(
      {
        next: x => cb(x.map(z => ({
          center: new L.LatLng(z.geometry.coordinates[1], z.geometry.coordinates[0]),
          bbox: new L.LatLngBounds(z.bbox.northEast, z.bbox.southWest),
          name: z.formatted_address,
        }))),
        error: err => this.logger.apiErrorsToDisplayErrors(err),
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types, max-params-no-constructor/max-params-no-constructor
  public reverse?(location: L.LatLngLiteral, scale: number, cb: GeocodingCallback, context?: any): void {
    // implement HERE api if needed
    cb([]);
  }

}


interface HereGeocodeSearchResults {
  items: {
    id: string;
    address: {
      city: string;
      countryCode: string;
      countryName: string;
      county: string;
      label: string;
      postalCode: string;
      state: string;
      stateCode: string;
    };
    localityType: string;
    mapView: {
      west: number;
      south: number;
      east: number;
      north: number;
    };
    position: {
      lat: number;
      lng: number;
    };
    resultType: string;
    scoring: {
      fieldScore: {
        city: number;
      };
      queryScore: number;
    } ;
    title: string;
  }[];
}
