<mtx-header></mtx-header>

<div class="d-flex align-items-center flex-column login">
    <div class="row container">
        <div class="col-12">

            <div class="col-12 title-bar" [translate]="'login.please-signin'">
            </div>

            <div class="card mx-auto rounded-0">

                <div class="card-body align-items-center justify-content-center">

                    <div class="d-flex flex-column h-100">
                        <div class="col-12">
                            <img src="/assets/images/logo_dark.png" class="mx-auto d-block pb-3" alt="MotoTrax" />
                        </div>
                        <div class="col-12 d-flex flex-column">
                            <a (click)="goToAngelTraxLogin()" class="btn btn-plain btn-block redirect-button">
                                {{ 'login.login-redirect' | translate }}
                            </a>
                        </div>
                    </div>

                </div>

                <div class="card-footer">
                    <p class="my-auto">
                        Having trouble? Call us at <a href="tel:18006731788" class="link-gold">1-800-673-1788</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <br>
</div>
