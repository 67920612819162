
import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import type { UntypedFormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { UserService } from 'src/app/admin/users/shared/user.service';
import { PersonDisplayNamePipe } from '../..';
import type { PersonNameParts } from '../../pipes/person-display-name.pipe';
import type { User } from 'src/app/admin/users/shared/user';
import type { PagedResponse } from '../../generic/paged-response';

@Component({
  selector: 'mtx-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
})
export class UserAutocompleteComponent implements OnInit {

  @Input() public translateText?: string;

  @Input() public fgName?: string;

  @Input() private readonly userObj?: PersonNameParts;

  public userModel?: PersonNameParts;

  public constructor(@Inject(UserService) private readonly userService: UserService,
    @Inject(ControlContainer) private readonly controlContainer: ControlContainer,
    @Inject(PersonDisplayNamePipe) private readonly displayNamePipe: PersonDisplayNamePipe) {}

  private get fg(): UntypedFormGroup | null {
    return this.fgName ? (this.controlContainer.control?.get(this.fgName) as UntypedFormGroup) : null;
  }

  public ngOnInit(): void {
    if (this.userObj) {
      this.userModel = this.userObj;
    }
  }

  public addUser(): void {
    const user = this.userModel;
    if (user?.id) {
      this.fg?.patchValue(user);
    } else {
      this.fg?.patchValue({ id: null });
    }
  }

  public search = (text$: Observable<string>): Observable<User[]> => text$.pipe(debounceTime(200),
    distinctUntilChanged(),
    switchMap((partOfName: string) => this.userService.search({ term: partOfName })
      .pipe(map((res: PagedResponse<User>) => {
        const self = (this.controlContainer.control?.value as User).id;
        const existingIds = this.getSelectorIDs();
        const finalResult = res.data.filter((x) => {
          const isInChildren =
                !existingIds.includes(x.id) && x.id !== self;
          return isInChildren;
        });
        return finalResult;
      }))));

  public formatter = (user: PersonNameParts): string => user.organization_identifier;

  public onBlur(): void {
    if (!(this.fg?.value as User)?.id) {
      this.userModel = undefined;
    }
  }

  private getSelectorIDs(): string[] {
    return [this.fg?.controls.id.value];
  }

}
