import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import type { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * custom {@link AbstractControl} form validators
 */

@Injectable()
export class MtxValidators {

  public constructor(@Inject(TranslateService) private readonly translate: TranslateService,
    @Inject(DatePipe) private readonly date: DatePipe) { }

  // eslint-disable-next-line class-methods-use-this
  public addCustomError(control: AbstractControl, error: Record<string, unknown>): void {
    if (control.errors) {
      Object.assign(control.errors, error);
    } else {
      control.setErrors(error);
    }
  }

  /**
   * Ensures that a datetime is not set in the future
   */
  public noFutureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const maxDateTime = Date.parse(control.value as string);
      const now = new Date();
      const nowTimeStamp = Date.parse(now.toString());

      const msg = this.translate.instant('validation-messages.no_future_date') as string;

      if (maxDateTime) {
        if (maxDateTime > nowTimeStamp) {
          return { noFutureDate: { msg: `${msg} ${this.date.transform(nowTimeStamp, 'short') ?? ''}` } };
        }
      }
      return null;
    };
  }

  /**
   * Ensures that a datetime is not set in the past
   */
  public noPastDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const maxDateTime = Date.parse(control.value as string);
      const now = new Date();
      const nowTimeStamp = Date.parse(now.toString());

      const msg = this.translate.instant('validation-messages.no_past_date') as string;

      if (maxDateTime) {
        if (maxDateTime < nowTimeStamp) {
          return { noPastDate: { msg: `${msg} ${this.date.transform(nowTimeStamp, 'short') ?? ''}` } };
        }
      }
      return null;
    };
  }

  // Ensures that, for a min datetime and max datetime, the min datetime does not exeed the max datetime
  public startBeforeEndTimestampValidator(startControlName: string,
    endControlName: string): ValidatorFn {
    // eslint-disable-next-line max-statements
    return (ac: AbstractControl): ValidationErrors | null => {
      const endControl = ac.get(endControlName) as FormControl<string | null>,
        startControl = ac.get(startControlName) as FormControl<string | null>;

      const maxDateTime = endControl.value,
        minDateTime = startControl.value;

      const msg = this.translate.instant('validation-messages.end_before_start', { value1: startControlName, value2: endControlName }) as string;

      if (minDateTime ?? maxDateTime) {
        if (minDateTime === null || maxDateTime === null || Date.parse(maxDateTime) < Date.parse(minDateTime)) {
          this.addCustomError(startControl, { startBeforeEnd: { msg } });
          this.addCustomError(endControl, { startBeforeEnd: { msg } });

          if (startControl.touched || endControl.touched) {
            startControl.markAsTouched();
            endControl.markAsTouched();
          }

          return { custom: { msg } };
        }
      }

      this.removeError(startControl, 'startBeforeEnd');
      this.removeError(endControl, 'startBeforeEnd');

      return null;
    };
  }

  // Ensures that, for a min number and max number, that the min does not exceed the max
  public minGreaterThanMaxValidator(minControlName: string,
    maxControlName: string): ValidatorFn {
    // eslint-disable-next-line max-statements
    return (ac: AbstractControl): ValidationErrors | null => {
      const maxControl = ac.get(maxControlName) as FormControl<number | null>,
        minControl = ac.get(minControlName) as FormControl<number | null>;

      minControl.markAsTouched();
      maxControl.markAsTouched();

      const max = maxControl.value,
        min = minControl.value;

      const msg = this.translate.instant('validation-messages.min_greater_than_max') as string;

      if (min ?? max) {
        if (min === null || max === null || max < min) {
          this.addCustomError(minControl, { minGreaterThanMax: { msg } });
          this.addCustomError(maxControl, { minGreaterThanMax: { msg } });

          return { custom: { msg } };
        }
      }

      this.removeError(minControl, 'minGreaterThanMax');
      this.removeError(maxControl, 'minGreaterThanMax');

      return null;
    };
  }

  private removeError(control: AbstractControl, error: string): void {
    delete control.errors?.[error];
    if (control.errors) {
      if (Object.keys(control.errors as Record<string, string>).length === 0) {
        control.setErrors(null);
      }
    }
  }

}
