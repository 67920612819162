/* eslint-disable max-classes-per-file */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { MultiPolygon, Polygon } from 'geojson';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PoliticalBoundaryService {

  public constructor(@Inject(HttpClient) private readonly http: HttpClient) { }

  private static osmToResult(osmResult: OSMResult): PoliticalBoundary {
    if (osmResult.geojson.type === 'Polygon') {
      return {
        name: osmResult.display_name,
        shape: {
          type: 'MultiPolygon',
          coordinates: [osmResult.geojson.coordinates],
        },
        osm_id: osmResult.osm_id,
      };
    }

    return {
      name: osmResult.display_name,
      shape: osmResult.geojson,
      osm_id: osmResult.osm_id,
    };
  }

  public search(searchParams: PoliticalBoundarySearchParams): Observable<PoliticalBoundary[]> {
    const encodedName = encodeURIComponent(searchParams.name);
    const url = `${environment.politicalBoundaryApiUrl}/search?q=${encodedName}&format=json&limit=25&polygon_geojson=1`;

    return this.http
      .get<OSMResult[]>(url)
      .pipe(map(x => x.filter(z => z.geojson.type === 'MultiPolygon' || z.geojson.type === 'Polygon').map(y => PoliticalBoundaryService.osmToResult(y))));
  }

}

export interface PoliticalBoundarySearchParams {
  name: string;
}

interface OSMResult {
  display_name: string;
  geojson: MultiPolygon | Polygon;
  osm_id: string;
}

export interface PoliticalBoundary {
  name: string;
  shape: MultiPolygon;
  osm_id: string;
}
