import { AuthService } from '../../auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import type { SafeUrl } from '@angular/platform-browser';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UrlFixerService } from '..';
import type { PipeTransform } from '@angular/core';
import { Inject, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Pipe({
  name: 'buildSecureVideoUrl',
})
export class BuildSecureVideoUrlPipe implements PipeTransform {

  private readonly moduleName?: string;

  public constructor(@Inject(AuthService) private readonly authService: AuthService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
    @Inject(UrlFixerService) private readonly urlFixerService: UrlFixerService) {
    this.moduleName = this.route.pathFromRoot[1]?.snapshot?.routeConfig?.path;
  }

  public transform(videoId: string, queryToken?: string): Observable<SafeUrl> {
    let token = '';
    let url = '';

    if (queryToken) {
      token = queryToken;
      url = `${environment.apiBaseUrl}/driver-incident/review/video/${videoId}?token=${token}`;
    } else {
      token = this.authService.getOauthAccessToken();
      if (this.moduleName === 'sav') {
        url = `${environment.apiBaseUrl}/sav/videos/${videoId}/transcoded?authorization_token=${token}`;
      } else {
        url = `${environment.apiBaseUrl}/driver-analytics/videos/${videoId}/transcoded?authorization_token=${token}`;
      }
    }

    if (!this.urlFixerService.needsOrgAddedToUrl(url)) {
      return of(this.sanitizer.bypassSecurityTrustUrl(url));
    }

    return this.urlFixerService.fixUrl(url).pipe(switchMap((finalUrl: string) => {
      const safeUrl = this.sanitizer.bypassSecurityTrustUrl(finalUrl);

      return of(safeUrl);
    }));
  }

}
