import { AuthService } from 'src/app/auth.service';
import { first } from 'rxjs/operators';
import type { OnInit } from '@angular/core';
import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';

// Directive decorator
@Directive({ selector: '[mtxIfUserHasPermission]' })
// Directive class
export class IfUserHasPermissionDirective implements OnInit {

  @Input() public mtxIfUserHasPermission?: string[];

  public constructor(@Inject(ElementRef) private readonly el: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2,
    @Inject(AuthService) private readonly authService: AuthService) {
    renderer.setStyle(el.nativeElement, 'display', 'none');
  }

  public ngOnInit(): void {
    this.authService.isAuthenticated$.pipe(first()).subscribe((isAuth) => {
      if (isAuth) {
        this.reset();
      }
    });
  }

  private reset(): void {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');

    if (!this.mtxIfUserHasPermission) {
      return;
    }

    if (this.mtxIfUserHasPermission.length === 0) {
      this.renderer.setStyle(this.el.nativeElement, 'display', '');
      return;
    }

    this.authService
      .userHasPermission(this.mtxIfUserHasPermission)
      .pipe(first())
      .subscribe((x) => {
        if (x) {
          this.renderer.setStyle(this.el.nativeElement, 'display', '');
        }
      });
  }

}
