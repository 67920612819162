import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import type { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

/**
 * Guard to make sure user is authenticated
 */
@Injectable()
export class AuthGuard {

  public constructor(@Inject(Router) private readonly router: Router,
    @Inject(AuthService) private readonly authService: AuthService) { }

  /**
   * Redirects to route if user is authenticated
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns boolean
   */
  public canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isDoneLoading$
      .pipe(filter(isDoneLoading => isDoneLoading))
      .pipe(switchMap(() => forkJoin({
        isLoggedIn: this.authService.isLoggedIn(),
        hasPermission: this.authService.userHasPermission(route.data.permissions as string[] ?? []),
      })))
      .pipe(tap((check) => {
        if (!check.isLoggedIn) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/login'], {
            queryParams: { redirect: state.url },
          });
        } else if (!check.hasPermission) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['error-pages/access-denied'], {
            skipLocationChange: true,
          });
        }
      }))
      .pipe(map(check => check.hasPermission));
  }

}
