import type { OnChanges } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import type { UserPrefs } from 'src/app/user/user-preferences/shared/user-prefs';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'mtx-time',
  templateUrl: './mtx-time.component.html',
  styleUrls: ['./mtx-time.component.scss'],
})
export class MtxTimeComponent implements OnChanges {

  @Input() public inputDate?: string | null | undefined = '';

  @Input() public inputStyles?: any;

  @Input() public useLocalTimeZone = false;

  public formattedTime = '';

  public userPrefsLoadingRequest$?: Observable<UserPrefs>;

  private currentUserPrefs: UserPrefs | undefined;

  public constructor(
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(LoggerService) private readonly loggerService: LoggerService,
  ) { }

  public ngOnChanges(): void {
    this.getSettings();
    const inputMoment = moment(this.inputDate, true);
    if (!this.useLocalTimeZone) {
      inputMoment.parseZone();
    }

    if (inputMoment.isValid()) {
      this.formattedTime = inputMoment.format(this.getFormatToUse());
    }
  }

  private getFormatToUse(): string {
    const formatToUse = this.currentUserPrefs?.use_24_hour_clock === true ? 'HH:mm:ss' : 'hh:mm:ss A';

    return formatToUse;
  }

  private getSettings(): void {
    this.userPrefsLoadingRequest$ = this.authService.getCurrentUserPrefs();
    this.userPrefsLoadingRequest$.subscribe({
      next: (res: UserPrefs) => {
        this.userPrefsLoadingRequest$ = undefined;
        this.currentUserPrefs = res;
      },
      error: (error: any) => {
        this.loggerService.apiErrorsToDisplayErrors(error);
        this.userPrefsLoadingRequest$ = undefined;
      },
    });
  }

}
