import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import type { Stop } from './stop';
import type { PagedResponse } from 'src/app/shared';
import { ParamSerializer } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import type { StopQueryOpts } from './stop-query-opts';

/**
 * Service for controlling route & stop data
 */
@Injectable()
export class GtfsService {

  /** API Url */
  public apiUrl = `${environment.apiBaseUrl}/transit/stops`;


  /** Array of stop options */
  public stopOptions: Stop[] = [];

  /**
   * Clears cache on login and logout
   */
  public constructor(@Inject(OAuthService) private readonly oauthService: OAuthService,
    @Inject(ParamSerializer) private readonly paramSerializer: ParamSerializer,
    @Inject(HttpClient) private readonly http: HttpClient) {}

  /**
   * Gets list of stops for dropdown options
   * @returns PagedResponse<Stop>
   */
  public getStopOptions(flushCache: boolean): Observable<Stop[]> {
    if (flushCache) {
      this.stopOptions = [];
    }

    if (this.stopOptions.length > 0) {
      return of(this.stopOptions);
    }

    return this.getAllStops().pipe(map((response: PagedResponse<Stop>) => {
      this.stopOptions = response.data;
      return this.stopOptions;
    }));
  }

  public autocomplete(filters: StopQueryOpts): Observable<Stop[]> {
    return this.search(filters).pipe(map(x => x.data));
  }

  /**
   * Searches for stops
   * @param filters Takes StopQueryOpts as filters for request
   * @returns PagedResponse<Stop>
   */
  public search(filters: StopQueryOpts): Observable<PagedResponse<Stop>> {
    if (filters.stop_id && !Array.isArray(filters.stop_id)) {
      filters.stop_id = [filters.stop_id];
    }

    const serializedParams = this.paramSerializer.serialize(filters);
    const url = `${this.apiUrl}?${serializedParams}`;

    const req = this.http.get<Stop[]>(url, { observe: 'response' }).pipe(map(res => ({
      data: res.body ?? [],
      count: parseInt(res.headers.get('X-RESULT-COUNT')!, 10),
    })));

    return req;
  }

  /**
   * Gets all stops
   * @returns PagedResponse<Stop>
   */
  private getAllStops(): Observable<PagedResponse<Stop>> {
    const url = `${this.apiUrl}`;

    const req = this.http.get<Stop[]>(url, { observe: 'response' }).pipe(map(response => ({
      data: response.body ?? [],
      count: parseInt(response.headers.get('X-RESULT-COUNT')!, 10),
    })));

    return req;
  }

}
