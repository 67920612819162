<mtx-header></mtx-header>

<div id="user-join org" class="container">
    <div class="row" style="padding-top: 10px;">
        <div class="col-12 col-lg-8 offset-lg-2">
            <mtx-alert-display [alerts]="messages"></mtx-alert-display>
        </div>
    </div>
    <div class="row" *ngIf="isReady">
        <div class="col-4 offset-4 bg-off-white error-container" style="text-align: center; margin-top: 25px;">
            <div [translate]="'admin.user-profile.org-invited'"></div>
            <br />
            <button type="button" class="btn btn-success btn-sm" (click)="acceptInvite()" [translate]="'general.accept'">
            </button>
            <br /><br />
            <div *ngIf="currentUserName">
                <span [translate]="'admin.invitations.logged-in-as'" ></span><strong>{{currentUserName.first_name}} {{currentUserName.last_name}}.</strong>
                <br>
                <br/>
                <a (click)="acceptInviteNotLoggedIn()" style="cursor: pointer;"><u [translate]="'login.not-you'"></u></a>
            </div>
        </div>
    </div>
    <!-- /.container -->
</div>