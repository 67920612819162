import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { ParamSerializer } from 'src/app/shared';
import type { PagedResponse } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import type { Organization } from './organization';
import type { OrganizationQueryOpts } from './organization-query-opts';

/**
 * Service for controlling organization data
 */
@Injectable()
export class UserOrganizationService {

  /** API Url */
  private readonly apiUrl = `${environment.apiBaseUrl}/user/organizations`;

  /**
   * Clears cache on login and logout
   */
  public constructor(@Inject(HttpClient) private readonly http: HttpClient,
    @Inject(ParamSerializer) private readonly paramSerializer: ParamSerializer) {}

  /**
   * Searches for organizations
   * @param filters Takes OrganizationQueryOpts as filters for request
   * @returns PagedResponse<Organization>
   */
  public search(filters: OrganizationQueryOpts): Observable<PagedResponse<Organization>> {
    const serializedParams = this.paramSerializer.serialize(filters);
    const url = `${this.apiUrl}?${serializedParams}`;

    const req = this.http
      .get<Organization[]>(url, { observe: 'response' })
      .pipe(map(response => ({
        data: response.body ?? [],
        count: parseInt(response.headers.get('X-RESULT-COUNT')!, 10),
      })));

    return req;
  }

}
