import { environment } from '../environments/environment';
import type { Observable, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { delay, map, share, takeWhile } from 'rxjs/operators';
import type { Event } from '@angular/router';
import { NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { UserPreferencesService } from './user/user-preferences/shared/user-preferences.service';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, DestroyRef, Inject } from '@angular/core';
import { IdleService } from './idle.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mtx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public isLoadingRoute = false;

  public isLoadingApp = true;

  public authSetupFailed = false;

  public authWindowReload?: Observable<number>;

  private readonly title = 'mototrax-webui';

  private readonly isProduction = environment.production;

  private logoutSubscription?: Subscription;

  private readonly loginSubscription?: Subscription;

  private authSub?: Subscription;

  /**
   * Sets google analytics and navigatorIntercepter
   */
  public constructor(@Inject(Router) private readonly router: Router,
    @Inject(TranslateService) private readonly translate: TranslateService,
    @Inject(IdleService) private readonly idleService: IdleService,
    @Inject(UserPreferencesService) private readonly userPreferencesService: UserPreferencesService,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(DestroyRef) private readonly destroy: DestroyRef) {
    router.events.subscribe((event) => {
      this.interceptNavigation(event);
    });

    this.authSub = this.authService.isAuthenticated$.subscribe({
      next: (isAuth) => {
        if (isAuth) {
          this.translate.use(this.userPreferencesService.language);
        }
      },
      error: () => {
        // sildently fail
      },
    });
  }

  /** Translates page */
  public ngOnInit(): void {
    this.configureTranslations();
    this.configureAuthentication();

    this.idleService.idle$.pipe(takeUntilDestroyed(this.destroy))
      .subscribe((isIdle) => {
        if (isIdle) {
          if (this.authService.isLoggedIn()) {
            const path = this.router.url;
            this.authService.logout(path);
          }
        }
      });

    this.router.events.pipe(delay(0)).subscribe((evt) => {
      this.interceptNavigation(evt);
    });
  }

  /**
   * Destroys subs on exit
   */
  public ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
      this.logoutSubscription = undefined;
    }

    if (this.authSub) {
      this.authSub.unsubscribe();
      this.authSub = undefined;
    }
  }

  private configureAuthentication(): void {
    this.authService.setup().subscribe({
      next: () => {
        this.isLoadingApp = false;
        this.isLoadingRoute = false;
      },
      error: () => {
        this.authSetupFailed = true;

        const timeToWait = 10;
        this.authWindowReload = timer(0, 1000).pipe(takeWhile((value: number) => value < timeToWait),
          map((value: number) => timeToWait - value),
          share());

        this.authWindowReload.subscribe({
          complete: () => {
            location.reload();
          },
        });
      },
    });
  }

  private configureTranslations(): void {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  /**
   * Makes sure navigation can't be interrupted
   * @param event Event
   */
  private interceptNavigation(event: Event): void {
    if (event instanceof NavigationStart) {
      this.isLoadingRoute = true;
    } else if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel
    ) {
      this.isLoadingRoute = false;
    } else if (event instanceof NavigationError) {
      if (environment.production) {
        window.location.href = window.location.origin + event.url;
      } else {
        this.isLoadingRoute = false;
      }
    }
  }

}
