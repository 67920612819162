import type { OnInit } from '@angular/core';
import { EventEmitter, Inject, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import type { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { VehicleService } from '../..';
import type { SubmittableFormGroup } from '../../generic/submittable-form-group';

@Component({
  selector: 'mtx-vehicle-autocomplete',
  templateUrl: './vehicle-autocomplete.component.html',
  styleUrls: ['./vehicle-autocomplete.component.scss'],
})
export class VehicleAutocompleteComponent implements OnInit {

  @Input() public translateText?: string;

  @Input() public fgName?: string;

  @Input() public vehicleObj?: Vehicle;

  @Output() public markAsTouched = new EventEmitter<void>();

  @Output() public vehicleAdded = new EventEmitter<Vehicle>();

  public vehicleModel?: Vehicle;

  public reqValFn = Validators.required;

  public constructor(@Inject(ControlContainer) private readonly controlContainer: ControlContainer,
    @Inject(VehicleService) private readonly vehicleService: VehicleService) {}

  public get fg(): SubmittableFormGroup | null {
    return this.fgName ? (this.controlContainer.control?.get(this.fgName) as SubmittableFormGroup) : null;
  }

  public ngOnInit(): void {
    if (this.vehicleObj) {
      // if an existing vehicle object is passed in, use it to populate autocomplete
      this.vehicleModel = this.vehicleObj;
    }
  }

  public addVehicle(vehicle: Vehicle): void {
    this.vehicleModel = vehicle;
    if (vehicle?.id) {
      this.fg?.patchValue(vehicle);
      this.vehicleAdded.emit(vehicle);
    } else {
      this.fg?.patchValue({ id: null });
    }
  }

  public search = (text$: Observable<string>): Observable<Vehicle[]> => text$.pipe(debounceTime(200),
    distinctUntilChanged(),
    switchMap((term: string) => this.vehicleService.autocomplete({ name: term, sort: '-name' }).pipe(map((res: Vehicle[]) => {
      if (term === '') {
        this.fg?.patchValue({ id: null, name: null });
      }
      const self = (this.controlContainer.control?.value as Vehicle).id;
      const existingIds = this.getSelectorIDs();
      const finalResult = res.filter((x) => {
        const isInChildren =
                !existingIds.includes(x.id) && x.id !== self;

        return isInChildren;
      });

      return finalResult;
    }))));

  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  public formatter = (vehicle: { id: string; name: string }): string => vehicle.name ?? '';

  public onBlur(): void {
    if (!(this.fg?.value as Vehicle)?.id) {
      this.vehicleModel = undefined;
    }
    this.fg?.controls.id.markAsTouched();
    this.fg?.controls.id.updateValueAndValidity({ onlySelf: true });
  }

  public onFocus(): void {
    this.markAsTouched.emit();
    if ((this.fg?.value as Vehicle)?.id) {
      this.vehicleModel = undefined;
      this.fg?.setValue({ id: null, name: null });
    }
  }

  private getSelectorIDs(): string[] {
    return [this.fg?.controls.id.value];
  }

}

interface Vehicle {
  id: string;
  name: string;
}
