import { Component } from '@angular/core';
import pkgInfo from '../../../../../package.json';

/**
 * Component for displaying footer view
 */
@Component({
  selector: 'mtx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  /** App version number */
  public appVersion = pkgInfo.version;

}
