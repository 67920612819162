import type { Observable } from 'rxjs';
import type { IReferenceType, PagedResponse } from '..';

export interface DriverQueryOpts {
  is_active?: boolean;
  coaching_emails_enabled?: boolean;
  organization_identifier?: string;
  'driver_group.id'?: number;
  'driver_group.name'?: string;
  first_name?: string;
  last_name?: string;
  term?: string;
  card_number?: string;
  limit?: number;
  offset?: number;
  sort?: string[] | string;
  authorization_token?: string;
}

export interface Group {
  id: string;
  name: string;
}

export interface Driver {
  id: string;
  first_name: string | null;
  last_name: string | null;
  driver_group: Group;
}

export abstract class DriverService {

  public abstract search(filters: DriverQueryOpts): Observable<PagedResponse<Driver>>;

  public abstract getOptions(): Observable<Driver[]>;

  public abstract getCardOptions(): Observable<IReferenceType[]>;

}
