import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import moment from 'moment';
import 'moment-duration-format';
import type { DurationInputArg2 } from 'moment';

/**
 * Pipe that converts durations of times to different formats
 */
@Pipe({ name: 'formatDuration',
  pure: true })
export class FormatDurationPipe implements PipeTransform {

  /**
   * Converts durations of times to different formats
   * @param raw Time
   * @param srcFormat Original format
   * @param tgtFormat New Format
   * @returns New duration
   */
  public transform(raw: number | string,
    srcFormat: DurationInputArg2 | string,
    tgtFormat: string): string {
    const time = moment.duration(raw, srcFormat as (moment.unitOfTime.DurationConstructor | undefined))
      .format(tgtFormat);

    // adds leading zeros if under a minute, duration format doesn't work correctly
    if (time.length < 3) {
      return `00:${moment.duration(raw, srcFormat as (moment.unitOfTime.DurationConstructor | undefined))
        .format(tgtFormat)}`;
    }
    return moment.duration(raw, srcFormat as (moment.unitOfTime.DurationConstructor | undefined)).format(tgtFormat);
  }

}
