<!-- HEADER -->
<nav id="header" class="container-fluid">
        <div class="row h-100">

            <div class="col-6 my-auto">

                <span class="d-none d-lg-block">
                        {{ date | date:'MMM dd, yyyy' }} | <a href="http://www.angeltrax.com">AngelTrax.com</a> | {{ appVersion }}
                    </span>

                <img src="assets/images/mtx-logo-sm.png" class="d-block d-lg-none" />

            </div>

            <div class="col-6 my-auto text-end">
                <div *ngIf="isLoggedIn" ngbDropdown class="d-inline-block" [placement]="'bottom-right'">
                    <a style="cursor:pointer" id="user-menu" ngbDropdownToggle><i class="fa fa-cog"></i></a>
                    <div ngbDropdownMenu aria-labelledby="user-menu">
                        <a class="user-menu-link" routerLink="/users/preferences">
                            <li class="dropdown-item">
                                <i class="fa fa-user"
                                    style="position: relative; left: 2px;"></i>&nbsp;&nbsp;
                                <span style="position: relative; left: 4px;"
                                    [translate]="'shared.preferences'"></span>
                            </li>
                        </a>

                        <a class="user-menu-link" (click)="openChangeOrgModal();">
                            <li class="dropdown-item">
                                <i class="mtx-icon mtx-icon-setup"></i>&nbsp;&nbsp;
                                <span [translate]="'shared.change-orgs'"></span>
                            </li>
                        </a>

                        <a class="user-menu-link" (click)="logout()">
                            <li class="dropdown-item">
                                <i class="fa fa-power-off" id="logout-link"></i>&nbsp;&nbsp;
                                <span [translate]="'shared.logout'"></span>
                            </li>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <ngb-alert type="danger">
      <span [translate]="'general.cjis-warning'"></span>
    </ngb-alert>
    <div class="col-12 text-center ie-deprecation-notice mt-3">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.02em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 252"><path d="M256 131.593c0-20.238-5.232-39.25-14.408-55.772c39.11-88.514-41.906-75.55-46.417-74.667c-17.164 3.359-33.044 8.751-47.691 15.587c-2.16-.122-4.334-.189-6.523-.189c-54.665 0-100.423 38.128-112.134 89.241C57.639 73.47 77.802 60.428 89.877 55.21a274.318 274.318 0 0 0-5.67 5.218c-.618.583-1.213 1.175-1.822 1.761a284.903 284.903 0 0 0-3.638 3.545c-.71.707-1.4 1.42-2.1 2.133a307.038 307.038 0 0 0-3.145 3.235c-.734.77-1.45 1.541-2.17 2.312a286.466 286.466 0 0 0-2.865 3.104c-.73.807-1.45 1.613-2.168 2.422a319.307 319.307 0 0 0-4.796 5.52a331.84 331.84 0 0 0-2.45 2.927c-.714.864-1.426 1.726-2.125 2.589a341.74 341.74 0 0 0-2.234 2.794c-.716.902-1.431 1.802-2.13 2.703c-.666.857-1.312 1.711-1.963 2.566c-.735.967-1.476 1.933-2.191 2.894c-.512.688-1.003 1.37-1.506 2.055a356.843 356.843 0 0 0-12.449 18.128c-.01.014-.02.028-.027.043a367.82 367.82 0 0 0-2.875 4.557c-.05.081-.102.163-.152.246a361.864 361.864 0 0 0-2.719 4.458l-.094.155a369.532 369.532 0 0 0-6.537 11.338C11.69 160.689 6.646 173.807 6.428 174.586c-32.718 116.97 69.396 67.575 83.645 60.201c15.343 7.58 32.615 11.846 50.888 11.846c50.021 0 92.58-31.93 108.422-76.519h-60.446c-8.944 15.11-26.163 25.345-45.945 25.345c-28.984 0-52.479-21.96-52.479-49.05h164.54c.624-4.851.947-9.796.947-14.816zM234.51 18.386c9.907 6.687 17.852 17.187 4.207 52.55c-13.088-21.048-32.778-37.559-56.181-46.634c10.646-5.141 37.01-16.016 51.974-5.916zM23.98 234.552c-8.07-8.275-9.496-28.429 8.31-65.154c8.985 25.835 26.916 47.482 50.092 61.22c-11.526 6.345-42.126 20.629-58.402 3.934zm66.279-119.601c.92-26.329 23.834-47.41 51.987-47.41c28.153 0 51.068 21.081 51.988 47.41H90.259z" fill="#1EBBEE"/></svg>
		Beginning September 1st, MotoTrax will no longer support the Internet Explorer 11 web browser.
		<br/>
		Please transition to a modern browser, such as Google Chrome, Firefox, or Microsoft Edge, for continued use of MotoTrax.
	</div>
    <!-- END: HEADER -->

