import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

/**
 * Component for authenicating users
 */
@Component({
  selector: 'mtx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  /** URL to redirect to */
  private redirectUrl: string | null = null;

  public constructor(@Inject(AuthService) protected authService: AuthService,
    @Inject(ActivatedRoute) protected activatedRoute: ActivatedRoute) {}

  /**
   * Checks for url params for redirecting
   */
  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((param) => {
      this.redirectUrl = param.redirect;
    });
  }

  public goToAngelTraxLogin(): void {
    this.authService.login(this.activatedRoute.snapshot.queryParams.redirect as string ?? '/');
  }

}
