import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import type { AlertMessage } from 'src/app/shared';
import { LoggerService,

  SubmittableFormGroup } from 'src/app/shared';
import type { UserPrefs } from '../user-preferences/shared/user-prefs';
import { UserPreferencesService } from './shared/user-preferences.service';

/**
 * Component for creating a user form based on passed object
 */
@Component({
  selector: 'mtx-user-form',
  template: '',
  styles: [],
})
export class UserPreferencesFormComponent {

  // expose ui
  /** Displays title */
  public title = 'User Preferences Form';

  /** Formgroup for user form */
  public userForm?: SubmittableFormGroup;

  /** Displays error/success messages */
  public messages: AlertMessage[] = [];

  /** Boolean if editting own Preferences */
  public editSelf = false;

  /** Boolean for keeping full header when user is logged in */
  public userAuth = true;

  // internal
  public constructor(@Inject(UserPreferencesService) protected userPreferencesService: UserPreferencesService,
    @Inject(LoggerService) protected loggerService: LoggerService) {}

  /**
   * Creates User object from passed formgroup
   * @param fg Takes formgroup for converting to User object
   * @returns User object from formgroup
   */
  public objFromFormGroup(fg: SubmittableFormGroup): UserPrefs {
    const value: {
      default_organization_id: string;
      default_timezone: string;
      locale: string;
      date_format: string;
      use_24_hour_clock: boolean | string;
    } = fg.value;

    return {
      default_organization: {
        id: value.default_organization_id,
        name: '',
        time_zone: '',
      },
      default_timezone: value.default_timezone,
      locale: value.locale,
      date_format: value.date_format,
      use_24_hour_clock: value.use_24_hour_clock === 'true' || value.use_24_hour_clock === true,
    };
  }

  /**
   * Takes user object and converts it into a formgroup
   * @param user User object for converting to formgroup
   * @returns Formgroup based on object passed
   */
  public formUserFromObj(userPrefs?: UserPrefs): SubmittableFormGroup {
    return new SubmittableFormGroup({
      default_organization_id: new UntypedFormControl(userPrefs?.default_organization.id),
      default_timezone: new UntypedFormControl(userPrefs?.default_timezone, [Validators.required]),
      locale: new UntypedFormControl(userPrefs?.locale, [Validators.required]),
      date_format: new UntypedFormControl(userPrefs?.date_format, [Validators.required]),
      use_24_hour_clock: new UntypedFormControl(userPrefs?.use_24_hour_clock, [Validators.required]),
    });
  }

  /**
   * Placeholder function?
   */
  public save(): void {
    throw new Error('not implemented');
  }

}
