<div [formGroup]="tgtFormGroup" *ngIf="tgtFormGroup" id="date-time-range-filter">
    <div class="row filter-section-header">
        <div class="col-lg-12" [translate]="'table-headings.date-time'">
        </div>
    </div>
    <div class="row my-3">
        <label for="filter_datetime_min_date_time" class="col-xl-3 pe-0 col-12 col-form-label col-form-label-sm" [translate]="'shared.start-date'"></label>
        <mtx-date-time-picker *ngIf="!this.useTimeWindow" class="col-lg-8 col-12 ps-xl-0" [tgtFormGroup]="tgtFormGroup" [tgtFormControlNameDate]="'min_date_time'" [tgtFormControlNameTime]="'min_date_time'"></mtx-date-time-picker>
        <mtx-date-time-picker *ngIf="this.useTimeWindow" class="col-lg-8 col-12 ps-xl-0" [tgtFormGroup]="tgtFormGroup" [tgtFormControlNameDate]="'window_start_date'" [tgtFormControlNameTime]="'window_start_time'"></mtx-date-time-picker>
    </div>

    <div class="row mb-3">
        <label for="filter_datetime_max_date_time" class="col-xl-3 pe-0 col-12 col-form-label col-form-label-sm" [translate]="'shared.end-date'"></label>
        <mtx-date-time-picker *ngIf="!this.useTimeWindow" class="col-lg-8 col-12 ps-xl-0" [tgtFormGroup]="tgtFormGroup" [tgtFormControlNameDate]="'max_date_time'" [tgtFormControlNameTime]="'max_date_time'"></mtx-date-time-picker>
        <mtx-date-time-picker *ngIf="this.useTimeWindow" class="col-lg-8 col-12 ps-xl-0" [tgtFormGroup]="tgtFormGroup" [tgtFormControlNameDate]="'window_end_date'" [tgtFormControlNameTime]="'window_end_time'"></mtx-date-time-picker>
    </div>

    <div class="row filter-section-content" *ngIf="allowsTimeWindow">
        <div class="col-12">
            <ng-template #dtExplanationTipContent>
                <div style="background:#fff; padding: 5px;" class="text-start">
                    <span [translate]="'shared.selects-a-time-range'"></span>
                    <br/><br/>
                    <span [translate]="'shared.time-range-example'"></span>
                </div>
            </ng-template>
            <div class="form-check">
                <div [ngbTooltip]="dtExplanationTipContent" #t="ngbTooltip">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" (change)="toggleUseTimeWindow($event)"> <span [translate]="'shared.apply-time'"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

</div>
