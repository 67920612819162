import { Component, Input } from '@angular/core';

@Component({
  selector: 'mtx-datetime',
  templateUrl: './mtx-datetime.component.html',
  styleUrls: ['./mtx-datetime.component.scss'],
})
export class MtxDatetimeComponent {

  @Input() public inputDate?: string | null | undefined = '';

  @Input() public inputStyles?: any;

  @Input() public includeDay?: boolean | null | undefined = true;

  @Input() public includeMonth?: boolean | null | undefined = true;

}
