import type { Observable } from 'rxjs';

interface Group {
  id: string;
  name: string;
}

interface Vehicle {
  id: string;
  name: string;
  group: Group;
}

export interface VehicleQueryOpts {
  id?: string[];
  name?: string;
  limit?: number;
  offset?: number;
  sort?: string[] | string;
}

export abstract class VehicleService {

  public abstract autocomplete(filters: VehicleQueryOpts): Observable<Vehicle[]>;

  public abstract getOptions(): Observable<Vehicle[]>;

}
