import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import 'moment-duration-format';

/**
 * Pipe that converts \n's to break tags
 */
@Pipe({ name: 'newLineToBreak',
  pure: true })
export class NewLineToBreakPipe implements PipeTransform {

  /**
   * Converts \n's in text to html breaks
   * @param raw String of text
   * @returns String with html breaks in it
   */
  public transform(raw: string): string {
    return raw.replace(/\n/g, '<br>');
  }

}
