import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { CanLoadGuard } from './can-load-guard.service';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./module-redirect/module-redirect.module')).ModuleRedirectModule,
  },

  {
    path: 'admin',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./admin/admin.module')).AdminModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['admin_*'],
      icon: 'fa fa-cogs',
      title: 'shared.admin',
      showInNav: true,
    },
  },

  {
    path: 'avl',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./avl/avl.module')).AvlModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['avl_*'],
      icon: 'fas fa-map-marker-alt',
      title: 'shared.avl',
      showInNav: true,
    },
  },

  {
    path: 'driver-behavior',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./driver-behavior/driver-behavior.module')).DriverBehaviorModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['da_*'],
      icon: 'fa fa-bar-chart',
      title: 'shared.driver-behavior',
      showInNav: true,
    },
  },

  {
    path: 'passenger-count',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./transit/transit.module')).TransitModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['transit_*'],
      icon: 'fa fa-bus',
      title: 'shared.passenger-count',
      showInNav: true,
    },
  },

  {
    path: 'student-tracking',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./student-tracking/student-tracking.module')).StudentTrackingModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['st_*'],
      icon: 'fas fa-id-card-alt',
      title: 'shared.student-tracking',
      showInNav: true,
    },
  },

  {
    path: 'sav',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./sav/sav.module')).SavModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['sav_*'],
      icon: 'mtx-icon mtx-icon-stop-sign',
      title: 'sav.sav',
      showInNav: true,
    },
  },

  {
    path: 'video',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./video/video.module')).VideoModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['vm_*'],
      icon: 'fas fa-download',
      title: 'general.video',
      showInNav: true,
    },
  },

  {
    path: 'health',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./health/health.module')).HealthModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['health_*'],
      icon: 'fas fa-file-waveform',
      title: 'health.health',
      showInNav: true,
    },
  },

  {
    path: 'live-view',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./live-view/live-view.module')).LiveViewModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['lv_*'],
      icon: 'fas fa-video',
      title: 'general.live-view',
      showInNav: true,
    },
  },

  {
    path: 'super',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./super/super.module')).SuperModule,
    canLoad: [CanLoadGuard],
    data: {
      permissions: ['super'],
      icon: 'fas fa-hat-wizard',
      title: 'general.super',
      showInNav: true,
    },
  },

  {
    path: 'error-pages',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./error-pages/error-pages.module')).ErrorPagesModule,
    data: { showInNav: false },
  },

  {
    path: 'driver-incident',
    pathMatch: 'prefix',
    loadChildren: async() => (await import('./driver-incident/driver-incident.module')).DriverIncidentModule,
    data: { showInNav: false },
  },

  {
    path: '**',
    redirectTo: '/',
    data: { showInNav: false },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
