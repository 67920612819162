import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { FormGroup } from '@angular/forms';

interface MinMaxDateTimeFilters {
  min_date_time: string;
  max_date_time: string;
  window_start_date: string;
  window_end_date: string;
  window_start_time: string;
  window_end_time: string;

}

/**
 * Component for displaying date/time range filters
 */
@Component({
  selector: 'mtx-filter-min-max-date-time',
  templateUrl: './filter-min-max-date-time.component.html',
  styleUrls: ['./filter-min-max-date-time.component.scss'],
})
export class FilterMinMaxDateTimeComponent implements OnInit, OnDestroy {

  /** Formgroup passed from element */
  @Input() public tgtFormGroup?: FormGroup;


  /** Whether to allow time filtering - passed from element */
  @Input() public allowsTimeWindow = false;

  /** Emitter for changes */
  @Output() public dateRangeChanged: EventEmitter<void> =
    new EventEmitter<void>();

  /** Whether to allow time filtering */
  public useTimeWindow = false;

  /** Bindings for ui bootstrap date/time pickers */
  public dateTimeFilters = {
    date_lower_limit: {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date(),
    },
    date_upper_limit: {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date(),
    },
    time_lower_limit: '00:00:00',
    time_upper_limit: '23:59:59',
  };

  private readonly destory$ = new Subject();

  /**
   * Sets initial filters
   */
  public ngOnInit(): void {
    this.tgtFormGroup?.valueChanges
      .pipe(takeUntil(this.destory$))
      .subscribe(() => {
        this.dateRangeChanged.emit();
      });
  }

  public ngOnDestroy(): void {
    this.destory$.next(null);
    this.destory$.complete();
  }

  /**
   * Applies time range to each day
   * @param val Time range
   */
  // eslint-disable-next-line max-statements
  public toggleUseTimeWindow(val: Event): void {
    if (!this.tgtFormGroup) {
      return;
    }

    this.useTimeWindow = (val.target as HTMLInputElement).checked;

    if (this.useTimeWindow) {
      // move min_date_time and max_date_time value to window
      const minDateTime = moment((this.tgtFormGroup.value as MinMaxDateTimeFilters).min_date_time,
        'YYYY-MM-DDTHH:mm:ss');
      const maxDateTime = moment((this.tgtFormGroup.value as MinMaxDateTimeFilters).max_date_time,
        'YYYY-MM-DDTHH:mm:ss');


      this.tgtFormGroup.controls.min_date_time.setValue(null);
      this.tgtFormGroup.controls.max_date_time.setValue(null);

      this.tgtFormGroup.controls.window_start_date.setValue(minDateTime.format('YYYY-MM-DD'));
      this.tgtFormGroup.controls.window_end_date.setValue(maxDateTime.format('YYYY-MM-DD'));
      this.tgtFormGroup.controls.window_start_time.setValue(minDateTime.format('HH:mm:ss'));
      this.tgtFormGroup.controls.window_end_time.setValue(maxDateTime.format('HH:mm:ss'));
    } else {
      // move window values to min/max_date_time
      // eslint-disable-next-line max-len
      const minDateTime = `${(this.tgtFormGroup.value as MinMaxDateTimeFilters).window_start_date}T${(this.tgtFormGroup.value as MinMaxDateTimeFilters).window_start_time}`;
      // eslint-disable-next-line max-len
      const maxDateTime = `${(this.tgtFormGroup.value as MinMaxDateTimeFilters).window_end_date}T${(this.tgtFormGroup.value as MinMaxDateTimeFilters).window_end_time}`;
      this.tgtFormGroup.controls.window_start_date.setValue(null);
      this.tgtFormGroup.controls.window_end_date.setValue(null);
      this.tgtFormGroup.controls.window_start_time.setValue(null);
      this.tgtFormGroup.controls.window_end_time.setValue(null);

      this.tgtFormGroup.controls.min_date_time.setValue(minDateTime);
      this.tgtFormGroup.controls.max_date_time.setValue(maxDateTime);
    }

    this.dateRangeChanged.emit();
  }

}
