import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'distanceConverter',
  pure: true,
})
export class DistanceConverterPipe implements PipeTransform {

  public transform(value: number | null | undefined, from: DistanceUnit, to: DistanceUnit): number {
    if (value === null || value === undefined) {
      throw new Error('Invalid input.');
    }

    if (from === 'mile' && to === 'km') {
      return value * 1.60934;
    }

    if (from === 'km' && to === 'mile') {
      return value * 0.621371;
    }

    if (from === 'mile' && to === 'mile') {
      return value;
    }

    if (from === 'km' && to === 'km') {
      return value;
    }
    throw new Error(`unsupported conversion from ${from} to ${to}`);
  }

}
export type DistanceUnit = 'km' | 'mile';
