import type { HttpResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { map, of } from 'rxjs';
import type { PagedResponse } from 'src/app/shared';
import { ParamSerializer } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import type { User, UserInvite } from './user';
import type { UserQueryOpts } from './user-query-opts';
import type { OrganizationIdentifier } from 'src/app/shared/abstracts/org-identifier.service';
import { OrganizationIdentifierService } from 'src/app/shared/abstracts/org-identifier.service';

/**
 * Service for controlling user data
 */
@Injectable({
  providedIn: 'root',
})
export class UserService extends OrganizationIdentifierService {

  /** API Url */
  public apiUrl: string;


  public cardOptions: { name: string; id: boolean; description: string }[] = [];

  /** Array of user options */
  private userOptions: User[] = [];

  /** Boolean for if there's no drivers */
  private noDrivers = false;

  /** Array of card status types */
  // private cardStatusTypes: IReferenceType[] = [];

  /**
   * Clears cache on login
   */
  public constructor(@Inject(HttpClient) private readonly http: HttpClient,
    @Inject(ParamSerializer) private readonly paramSerializer: ParamSerializer,
    @Inject(TranslateService) private readonly translate: TranslateService) {
    super();
    this.apiUrl = `${environment.apiBaseUrl}/admin/users`;
  }

  /**
   * Gets card status types for dropdown
   */
  public getCardOptions(): Observable<{ name: string; id: boolean; description: string }[]> {
    const req = this.translate.get([
      'options.active',
      'options.inactive',
    ]).pipe(map((translations: Record<string, string>) => {
      this.cardOptions = [
        { name: translations['options.active'],
          id: true,
          description: '' },
        {
          name: translations['options.inactive'],
          id: false,
          description: '',
        },
      ];
      return this.cardOptions;
    }));

    return req as Observable<{ name: string; id: boolean; description: string }[]>;
  }

  /**
   * Creates new user
   * @param user Takes user as object to create
   * @returns user
   */
  public create(user: UserInvite): Observable<UserInvite> {
    const url = `${this.apiUrl}`;

    const req = this.http.post<UserInvite>(url, user);

    return req;
  }

  /**
   * Searches for users
   * @param filters Takes UserQueryOpts as filters for request
   * @returns PagedResponse<User>
   */
  public search(filters: UserQueryOpts): Observable<PagedResponse<User>> {
    const serializedParams = this.paramSerializer.serialize(filters);
    const url = `${this.apiUrl}?${serializedParams}`;

    const req = this.http.get<User[]>(url, { observe: 'response' }).pipe(map((response: HttpResponse<User[]>) => ({
      data: response.body ?? [],
      count: parseInt(response.headers.get('X-RESULT-COUNT')!, 10),
    })));

    return req as Observable<PagedResponse<User>>;
  }

  public getOptions(): Observable<OrganizationIdentifier[]> {
    const req = this.search({ limit: 10000 });
    return req.pipe(map(x => x.data));
  }

  /**
   * Gets list of users for dropdown options
   * @returns User[]
   */
  public getDriverOptions(): Observable<User[]> {
    if (this.userOptions.length > 0 || this.noDrivers) {
      return of(this.userOptions);
    }

    return this.search({
      limit: 10000,
      type: 'driver',
      sort: 'last_name',
    }).pipe(map((response: PagedResponse<User>) => {
      if (response.count === 0) {
        this.noDrivers = true;
      }
      this.userOptions = response.data;
      return this.userOptions;
    }));
  }

  /**
   * Finds user by ID
   * @param id Takes string ID for filters on request
   * @returns User
   */
  public getById(id: string): Observable<User> {
    const url = `${this.apiUrl}/${id}`;

    const req = this.http.get<User>(url);

    return req;
  }

  /**
   * Returns download URL string from API for CSV/PDF
   * @param filters Takes PeopleQueryOpts filters object
   * @param type Takes string file type: csv or pdf
   * @returns Returns download url
   */
  public getDownloadUri(filters: UserQueryOpts,
    type: string): Observable<string> {
    const mFilters: UserQueryOpts = { ...filters };

    delete mFilters.offset;
    delete mFilters.limit;

    const serializedParams = this.paramSerializer.serialize(mFilters);
    return of(`${this.apiUrl}.${type}?${serializedParams}`);
  }

  /**
   * Updates user
   * @param user Takes user as object to create
   * @returns user
   */
  public update(user: User): Observable<User> {
    const url = `${this.apiUrl}/${user.id}`;
    const req = this.http.put<User>(url, user);

    return req;
  }

  /**
   * Removes user
   * @param id Takes ID to delete
   * @returns void
   */
  public remove(id: string): Observable<void> {
    const url = `${this.apiUrl}/${id}`;
    const req = this.http.delete(url).pipe(map(() => undefined));

    return req as Observable<void>;
  }

}
