import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import type { IReferenceType } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import type { OrganizationProfile } from './organization-profile';

/**
 * Service for controlling organization data
 */
@Injectable()
export class OrganizationProfileService {

  /** Sets language of app */
  public language = 'en';

  /** API Url */
  private readonly apiUrl = `${environment.apiBaseUrl}/admin`;

  /** Array of US state abbreviations */
  private stateOptions: IReferenceType[] = [];

  /**
   * Clears cache on login and logout
   */
  public constructor(@Inject(HttpClient) private readonly http: HttpClient) {}

  /**
   * Gets timezones object
   */
  public getTimezones(): Observable<string[]> {
    return of(moment.tz.names());
  }

  /**
   * Gets US state options for dropdown
   */
  // eslint-disable-next-line max-lines-per-function
  public getStateOptions(): Observable<IReferenceType[]> {
    this.stateOptions = [
      { name: 'Alabama',
        code: 'AL' },
      { name: 'Alaska',
        code: 'AK' },
      { name: 'American Samoa',
        code: 'AS' },
      { name: 'Arizona',
        code: 'AZ' },
      { name: 'Arkansas',
        code: 'AR' },
      { name: 'California',
        code: 'CA' },
      { name: 'Colorado',
        code: 'CO' },
      { name: 'Connecticut',
        code: 'CT' },
      { name: 'Delaware',
        code: 'DE' },
      { name: 'District Of Columbia',
        code: 'DC' },
      { name: 'Florida',
        code: 'FL' },
      { name: 'Georgia',
        code: 'GA' },
      { name: 'Guam',
        code: 'GU' },
      { name: 'Hawaii',
        code: 'HI' },
      { name: 'Idaho',
        code: 'ID' },
      { name: 'Illinois',
        code: 'IL' },
      { name: 'Indiana',
        code: 'IN' },
      { name: 'Iowa',
        code: 'IA' },
      { name: 'Kansas',
        code: 'KS' },
      { name: 'Kentucky',
        code: 'KY' },
      { name: 'Louisiana',
        code: 'LA' },
      { name: 'Maine',
        code: 'ME' },
      { name: 'Marshall Islands',
        code: 'MH' },
      { name: 'Maryland',
        code: 'MD' },
      { name: 'Massachusetts',
        code: 'MA' },
      { name: 'Michigan',
        code: 'MI' },
      { name: 'Minnesota',
        code: 'MN' },
      { name: 'Mississippi',
        code: 'MS' },
      { name: 'Missouri',
        code: 'MO' },
      { name: 'Montana',
        code: 'MT' },
      { name: 'Nebraska',
        code: 'NE' },
      { name: 'Nevada',
        code: 'NV' },
      { name: 'New Hampshire',
        code: 'NH' },
      { name: 'New Jersey',
        code: 'NJ' },
      { name: 'New Mexico',
        code: 'NM' },
      { name: 'New York',
        code: 'NY' },
      { name: 'North Carolina',
        code: 'NC' },
      { name: 'North Dakota',
        code: 'ND' },
      { name: 'Northern Mariana Islands',
        code: 'MP' },
      { name: 'Ohio',
        code: 'OH' },
      { name: 'Oklahoma',
        code: 'OK' },
      { name: 'Oregon',
        code: 'OR' },
      { name: 'Palau',
        code: 'PW' },
      { name: 'Pennsylvania',
        code: 'PA' },
      { name: 'Puerto Rico',
        code: 'PR' },
      { name: 'Rhode Island',
        code: 'RI' },
      { name: 'South Carolina',
        code: 'SC' },
      { name: 'South Dakota',
        code: 'SD' },
      { name: 'Tennessee',
        code: 'TN' },
      { name: 'Texas',
        code: 'TX' },
      { name: 'Utah',
        code: 'UT' },
      { name: 'Vermont',
        code: 'VT' },
      { name: 'Virgin Islands',
        code: 'VI' },
      { name: 'Virginia',
        code: 'VA' },
      { name: 'Washington',
        code: 'WA' },
      { name: 'West Virginia',
        code: 'WV' },
      { name: 'Wisconsin',
        code: 'WI' },
      { name: 'Wyoming',
        code: 'WY' },
    ];

    return of(this.stateOptions);
  }

  /**
   * Gets current Organization
   * @returns Organization
   */
  public getCurrentOrganization(): Observable<OrganizationProfile> {
    const url = `${this.apiUrl}/settings`;
    const req = this.http.get<OrganizationProfile>(url);

    return req;
  }

  /**
   * Updates current Organization
   * @param organization Takes Organization as object to create
   * @returns Organization
   */
  public updateCurrentOrganizationProfile(organization: OrganizationProfile): Observable<OrganizationProfile> {
    const url = `${this.apiUrl}/settings`;
    const req = this.http.put<OrganizationProfile>(url, organization);

    return req;
  }

}
