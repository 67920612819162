import type { Observable } from 'rxjs';

export interface OrganizationIdentifier {
  id: string;
  organization_identifier: string;
}

export abstract class OrganizationIdentifierService {

  public abstract getOptions(): Observable<OrganizationIdentifier[]>;

}
