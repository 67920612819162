import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationEditModalComponent } from '../user/notification-edit-modal/notification-edit-modal.component';
import { AlertDisplayComponent } from './components/alert-display/alert-display.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DownloadProgressModalComponent } from './components/download-progress-modal/download-progress-modal.component';
import { DriverAutocompleteComponent } from './components/driver-autocomplete/driver-autocomplete.component';
import { FilterMinMaxDateTimeComponent } from './components/filter-min-max-date-time/filter-min-max-date-time.component';
import { FilterVehicleByGroupComponent } from './components/filter-vehicle-by-group/filter-vehicle-by-group.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderFullComponent } from './components/header-full/header-full.component';
import { RouterHeaderComponent } from './components/header-full/router-header/router-header.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { SortToggleComponent } from './components/sort-toggle/sort-toggle.component';
import { StudentAutocompleteComponent } from './components/student-autocomplete/student-autocomplete.component';
import { UserAutocompleteComponent } from './components/user-autocomplete/user-autocomplete.component';
import { ValidationMessagesComponent } from './components/validation-message/validation-message.component';
import { ValidityIndicatorComponent } from './components/validity-indicator/validity-indicator.component';
import { VehicleAutocompleteComponent } from './components/vehicle-autocomplete/vehicle-autocomplete.component';
import { StopAutocompleteComponent } from './components/stop-autocomplete/stop-autocomplete.component';
import { IfUserHasPermissionDirective } from './directives/if-user-has-permission/if-user-has-permission.directive';
import { AlertTypePipe } from './pipes/alert-type.pipe';
import { BuildSecureVideoUrlPipe } from './pipes/build-secure-video-url.pipe';
import { CallbackPipe } from './pipes/callback.pipe';
import { FilterByValuePipe } from './pipes/filter-by-value.pipe';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { AbbreviateNumberPipe } from './pipes/abbreviate-number.pipe';
import { FormatDurationPipe } from './pipes/format-duration.pipe';
import { FormatVideoTimePipe } from './pipes/format-video-time.pipe';
import { NewLineToBreakPipe } from './pipes/newline-to-break.pipe';
import { PersonDisplayNamePipe } from './pipes/person-display-name.pipe';
import { SearchByNamePipe } from './pipes/search-by-name.pipe';
import { SpeedConverterPipe } from './pipes/speed-converter.pipe';
import { ToFixedPipe } from './pipes/to-fixed.pipe';
import { VehicleArrayNameJoinPipe } from './pipes/vehicle-array-name-join.pipe';
import { VideoToVideoChannelPipe } from './pipes/video-download-to-video-channel.pipe';
import { LoggerService } from './services/logger.service';
import { UrlFixerService } from './services/url-fixer.service';
import { DistanceConverterPipe } from './pipes/distance-converter.pipe';
import { MtxDateComponent } from './components/mtx-date/mtx-date.component';
import { MtxTimeComponent } from './components/mtx-time/mtx-time.component';
import { MtxDatetimeComponent } from './components/mtx-datetime/mtx-datetime.component';
import { PoliticalBoundaryModalComponent } from './components/political-boundary-modal/political-boundary-modal.component';
import { PoliticalBoundaryService } from './services/political-boundary.service';
import { SpeedTooltipDirective } from './directives/speed-tooltip/speed-tooltip.directive';
import { FilterLinksByPermissionPipe } from './pipes/filter-links-by-permission.pipe';
import { SpeedThresholdsModalComponent } from './components/speed-thresholds-modal/speed-thresholds-modal.component';
import { CopyModalComponent } from './components/copy-created-modal/copy-modal.component';
import { MatchGeofenceToMileagePipe } from './pipes/match-geofence-to-mileage.pipe';
import { MtxValidators } from './custom-validators/mtx-validators';
import { FilterByOrgIdentifierComponent } from './components/filter-by-org-identifier/filter-by-org-identifier.component';
import { SyncQueryParamsDirective } from './directives/sync-query-params/sync-query-params.directive';
import { FilterDriverByGroupComponent } from './components/filter-driver-by-group/filter-driver-by-group.component';
import { NouisliderModule } from 'ng2-nouislider';
import { GenericOptionService } from './services/generic-options.service';
import { EncodeHTMLPipe } from './pipes/encode-html.pipe';

/**
 * Module for various shared directives, pipes, classes, and components that are shared across mototrax
 */
@NgModule({
  declarations: [
    AlertDisplayComponent,
    AlertTypePipe,
    LoadingDialogComponent,
    ConfirmationModalComponent,
    HelpModalComponent,
    HeaderFullComponent,
    ValidationMessagesComponent,
    FooterComponent,
    FilterVehicleByGroupComponent,
    FilterDriverByGroupComponent,
    HeaderComponent,
    FormatAddressPipe,
    FormatDurationPipe,
    AbbreviateNumberPipe,
    DistanceConverterPipe,
    ValidityIndicatorComponent,
    ToFixedPipe,
    FilterByValuePipe,
    SortToggleComponent,
    NewLineToBreakPipe,
    FilterMinMaxDateTimeComponent,
    NotificationEditModalComponent,
    SearchByNamePipe,
    IfUserHasPermissionDirective,
    FormatVideoTimePipe,
    BuildSecureVideoUrlPipe,
    SpeedConverterPipe,
    CallbackPipe,
    PersonDisplayNamePipe,
    FilterLinksByPermissionPipe,
    VehicleArrayNameJoinPipe,
    VideoToVideoChannelPipe,
    DownloadProgressModalComponent,
    UserAutocompleteComponent,
    StudentAutocompleteComponent,
    RouterHeaderComponent,
    DateTimePickerComponent,
    DriverAutocompleteComponent,
    VehicleAutocompleteComponent,
    StopAutocompleteComponent,
    MtxDateComponent,
    MtxTimeComponent,
    MtxDatetimeComponent,
    PoliticalBoundaryModalComponent,
    SpeedTooltipDirective,
    SpeedThresholdsModalComponent,
    CopyModalComponent,
    MatchGeofenceToMileagePipe,
    FilterByOrgIdentifierComponent,
    SyncQueryParamsDirective,
    FilterDriverByGroupComponent,
    EncodeHTMLPipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NouisliderModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    AlertDisplayComponent,
    LoadingDialogComponent,
    MtxDateComponent,
    MtxDatetimeComponent,
    MtxTimeComponent,
    ConfirmationModalComponent,
    HelpModalComponent,
    HeaderFullComponent,
    ValidationMessagesComponent,
    FooterComponent,
    FilterVehicleByGroupComponent,
    FilterDriverByGroupComponent,
    HeaderComponent,
    FormatAddressPipe,
    FormatDurationPipe,
    AbbreviateNumberPipe,
    DistanceConverterPipe,
    ValidityIndicatorComponent,
    ToFixedPipe,
    SortToggleComponent,
    NewLineToBreakPipe,
    FilterByValuePipe,
    FilterMinMaxDateTimeComponent,
    SearchByNamePipe,
    IfUserHasPermissionDirective,
    SpeedTooltipDirective,
    FormatVideoTimePipe,
    BuildSecureVideoUrlPipe,
    SpeedConverterPipe,
    PersonDisplayNamePipe,
    VehicleArrayNameJoinPipe,
    VideoToVideoChannelPipe,
    FilterLinksByPermissionPipe,
    DownloadProgressModalComponent,
    UserAutocompleteComponent,
    StudentAutocompleteComponent,
    RouterHeaderComponent,
    DateTimePickerComponent,
    DriverAutocompleteComponent,
    VehicleAutocompleteComponent,
    StopAutocompleteComponent,
    CallbackPipe,
    PoliticalBoundaryModalComponent,
    MatchGeofenceToMileagePipe,
    FilterByOrgIdentifierComponent,
    SyncQueryParamsDirective,
    EncodeHTMLPipe,
  ],
  providers: [
    LoggerService,
    NgbActiveModal,
    PersonDisplayNamePipe,
    AbbreviateNumberPipe,
    DistanceConverterPipe,
    VehicleArrayNameJoinPipe,
    UrlFixerService,
    BuildSecureVideoUrlPipe,
    FilterLinksByPermissionPipe,
    SpeedConverterPipe,
    FormatAddressPipe,
    PoliticalBoundaryService,
    MtxValidators,
    DatePipe,
    GenericOptionService,
  ],
})
export class SharedModule {}
