/* eslint-disable no-prototype-builtins */
import { DatePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import type { AbstractControl, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

/**
 * Component to check if form field is valid and display a message if not
 */
@Component({
  selector: 'mtx-validation-messages',
  template: `
  <span *ngIf="(control?.touched || submitted) && control?.errors">

    <div class="form-control-feedback" *ngFor="let msg of validationMessages">
      {{ msg}}
    </div>
  </span>

<!--

    <div
      class="form-control-feedback"
      *ngIf="control?.hasError('required')"
      [translate]="'validation-messages.required'"
    ></div>
    <div class="form-control-feedback" *ngIf="control?.hasError('minlength')">
      <span [translate]="'validation-messages.at-least'"></span>
      {{ control?.errors?.minlength?.requiredLength }}
      <span [translate]="'validation-messages.characters-required'"></span>
    </div>
    <div class="form-control-feedback" *ngIf="control?.hasError('maxlength')">
      <span [translate]="'validation-messages.cannot-exceed'"></span>
      {{ control?.errors?.maxlength?.requiredLength }}
      <span [translate]="'validation-messages.characters'"></span>
    </div>
    <div class="form-control-feedback" *ngIf="control?.hasError('pattern')">
    <span [translate]="'validation-messages.required-format'"></span
    ><span *ngIf="example">
    <br>
        <span [translate]="'validation-messages.example'"></span> {{ example }}</span
      >
    </div>
    <div class="form-control-feedback" *ngIf="control?.hasError('email')">
      <span [translate]="'validation-messages.invalid-email'"></span
      ><span *ngIf="example">
        <span [translate]="'validation-messages.example'"></span> {{ example }}</span
      >
    </div>
    <div class="form-control-feedback" *ngIf="control?.hasError('invalidDate')">
      Invalid Date.<span *ngIf="example">
        <span [translate]="'validation-messages.example'"></span> {{ example }}</span
      >
    </div>
    <div class="form-control-feedback" *ngIf="control?.hasError('invalidTime')">
      Invalid Time.<span *ngIf="example">
        <span [translate]="'validation-messages.example'"></span> {{ example }}</span
      >
    </div> -->
  `,
  styles: [
    `
      .form-control-feedback {
        font-size: 10px;
        color: #bb0000;
      }
    `,
  ],
})
export class ValidationMessagesComponent {

  /** Whether form has been submitted */
  @Input() public submitted?: boolean;

  /** Formcontrol to test for validity */
  @Input() public control?: AbstractControl | UntypedFormControl | null | undefined;

  /** Example to output to demonstrate valid inputs */
  @Input() public example?: string;

  /** Whether to only show errors */
  @Input() public showErrorsOnly = false;

  public constructor(
    @Inject(TranslateService) private readonly translate: TranslateService,
    @Inject(DatePipe) private readonly datePipe: DatePipe,

  ) { }


  // eslint-disable-next-line max-lines-per-function
  public get validationMessages(): string[] {
    let messages: string[] = [];
    if (this.control?.errors) {
      // eslint-disable-next-line max-lines-per-function
      Object.keys(this.control.errors).forEach((err) => {
        switch (err) {
          case 'required':
            this.translate.get('validation-messages.required').pipe(first())
              .subscribe((trans: string) => messages.push(trans));
            break;

          case
            'minlength': messages.push(`${this.translate.instant('validation-messages.at-least-char', { value1: (this.control?.errors?.minlength as Record<string, string>).requiredLength }) as string}`);
            break;
          case
            'maxlength': messages.push(`${this.translate.instant('validation-messages.cannot-exceed-char', { value1: (this.control?.errors?.maxlength as Record<string, string>).requiredLength }) as string}`);
            break;
          case
            'min': messages.push(`${this.translate.instant('validation-messages.at-least', { value1: (this.control?.errors?.min as Record<string, string>).min }) as string}`);
            break;
          case
            'max': messages.push(`${this.translate.instant('validation-messages.cannot-exceed', { value1: (this.control?.errors?.max as Record<string, string>).max }) as string}`);
            break;
          case 'pattern':
            this.translate.get('validation-messages.required-format').pipe(first())
              .subscribe((trans: string) => messages.push(trans));
            break;

          case 'email':
            this.translate.get('validation-messages.invalid-email').pipe(first())
              .subscribe((trans: string) => messages.push(trans));
            break;

          case 'invalidDate':
            this.translate.get('validation-messages.invalid-date').pipe(first())
              .subscribe((trans: string) => messages.push(trans));
            break;

          case 'invalidTime':
            this.translate.get('validation-messages.invalid-time').pipe(first())
              .subscribe((trans: string) => messages.push(trans));
            break;
          case 'dueDateBeforeCourtDate':
            messages.push(this.translate.instant('validation-messages.due-date-before-court-date', { value1: this.datePipe.transform(this.control?.errors?.dueDateBeforeCourtDate as string) }) as string);
            break;
          case 'dueDateBeforeCurrentDueDate':
            messages.push(this.translate.instant('validation-messages.due-date-before-current-due-date', { value1: this.datePipe.transform(this.control?.errors?.dueDateBeforeCurrentDueDate as string) }) as string);
            break;
          case 'dueDateChanged':
            messages.push(this.translate.instant('validation-messages.due-date-changed', { value1: this.datePipe.transform(this.control?.errors?.dueDateChanged as string) }) as string);
            break;
          default:
            messages = messages.concat(this.tryReadMessage(this.control?.errors?.[err]));
        }
      });
    }
    return messages;
  }

  private tryReadMessage(error: any): string[] {
    const errArr: string[] = [];
    if (error instanceof Array) {
      error.forEach((err) => {
        if (err.hasOwnProperty('msg') && typeof err.msg === 'string') {
          errArr.push(err.msg as string);
        }
      });
      return errArr;
    } else if (error instanceof Object) {
      if (error.hasOwnProperty('msg') && (typeof error.msg === 'string')) {
        return [error.msg];
      // eslint-disable-next-line no-else-return
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        Object.keys(error).forEach((err) => {
          if (error[err].hasOwnProperty('msg') && (typeof error[err].msg === 'string')) {
            errArr.push(error[err].msg as string);
          }
        });
        if (errArr.length > 0) {
          return errArr;
        }
      }
    }

    return ['Unknown error'];
  }

}
