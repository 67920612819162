import { HttpClient } from '@angular/common/http';
import type { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { MotoTraxFile } from '../generic/file';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {

  public constructor(@Inject(HttpClient) private readonly http: HttpClient) {}

  public download(url: string): Observable<MotoTraxFile> {
    return this.http
      .get(url, { observe: 'response',
        responseType: 'blob' })
      .pipe(map((res: HttpResponse<Blob>) => {
        const contentDispositionHeader = res.headers.get('content-disposition');
        const contentType = res.headers.get('content-type');

        const fileName = contentDispositionHeader
          ?.split(';')[1]
          ?.trim()
          .split('=')[1]
          ?.replace(/"/g, '');
        return {
          data: res.body ?? new Blob(),
          name: fileName ?? 'file',
          contentType: contentType ?? 'application/octet-stream',
        };
      }));
  }

}
