import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import type { UserOrg } from 'src/app/auth.service';

/**
 * url fixer service
 */
@Injectable()
export class UrlFixerService {

  public constructor(@Inject(AuthService) private readonly authService: AuthService) { }

  // eslint-disable-next-line max-lines-per-function
  public fixUrl(url: string): Observable<string> {
    return this.authService.getCurrentOrganization().pipe(map((usr: UserOrg) => {
      let modfiedUrl = url.replace(`${environment.apiBaseUrl}/avl`,
        `${environment.apiBaseUrl}/avl/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/transit`,
        `${environment.apiBaseUrl}/transit/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/student-tracking`,
        `${environment.apiBaseUrl}/student-tracking/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/admin`,
        `${environment.apiBaseUrl}/admin/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/user/notifications`,
        `${environment.apiBaseUrl}/user/${usr.id}/notifications`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/driver-analytics`,
        `${environment.apiBaseUrl}/driver-analytics/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/sav`,
        `${environment.apiBaseUrl}/sav/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/video`,
        `${environment.apiBaseUrl}/video/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/health`,
        `${environment.apiBaseUrl}/health/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/live-view`,
        `${environment.apiBaseUrl}/live-view/${usr.id}`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/super/videos`,
        `${environment.apiBaseUrl}/super/organizations/${usr.id}/videos`);

      /*
       * this will break super/vehicle/stats url
       * url = url.replace(`${environment.apiBaseUrl}/super/vehicles`, `${environment.apiBaseUrl}/super/${o.id}/vehicles`);
       */
      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/super/driver-analytics/metric-overrides`,
        `${environment.apiBaseUrl}/super/driver-analytics/${usr.id}/metric-overrides`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/super/behavior/logs`,
        `${environment.apiBaseUrl}/super/organizations/${usr.id}/behavior/logs`);

      modfiedUrl = modfiedUrl.replace(`${environment.apiBaseUrl}/super/jurisdictions`,
        `${environment.apiBaseUrl}/super/organizations/${usr.id}/jurisdictions`);

      return modfiedUrl;
    }));
  }

  public needsOrgAddedToUrl(url: string): boolean {
    return (
      url.startsWith(`${environment.apiBaseUrl}/avl`) ||
      url.startsWith(`${environment.apiBaseUrl}/admin`) ||
      url.startsWith(`${environment.apiBaseUrl}/user/notifications`) ||
      url.startsWith(`${environment.apiBaseUrl}/transit`) ||
      url.startsWith(`${environment.apiBaseUrl}/student-tracking`) ||
      url.startsWith(`${environment.apiBaseUrl}/driver-analytics`) ||
      url.startsWith(`${environment.apiBaseUrl}/sav`) ||
      url.startsWith(`${environment.apiBaseUrl}/video`) ||
      url.startsWith(`${environment.apiBaseUrl}/health`) ||
      url.startsWith(`${environment.apiBaseUrl}/super/videos`) ||

      /*
       * this will break super/vehicle/stats url
       * || url.startsWith(`${environment.apiBaseUrl}/super/vehicles`)
       */
      url.startsWith(`${environment.apiBaseUrl}/super/driver-analytics/metric-overrides`) ||
      url.startsWith(`${environment.apiBaseUrl}/super/behavior/logs`) ||
      url.startsWith(`${environment.apiBaseUrl}/super/jurisdictions`) ||
      url.startsWith(`${environment.apiBaseUrl}/live-view`)
    );
  }

}
