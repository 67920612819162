import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { IReferenceType } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import type { UserNotification } from './user-notification';

/**
 * Service for retrieving user notification data
 */
@Injectable()
export class UserNotificationService {

  /** Array of notification type options, event types that allow notifications */
  public notifEventTypeOptions: IReferenceType[] = [];

  /** API Url */
  private readonly apiUrl = `${environment.apiBaseUrl}/user/notifications`;

  public constructor(@Inject(HttpClient) private readonly http: HttpClient) {}

  /**
   * Searches for notifications
   * @returns PagedResponse<UserNotification>
   */
  public search(): Observable<UserNotification[]> {
    const url = `${this.apiUrl}`;

    const req = this.http.get<UserNotification[]>(url);

    return req;
  }

  /**
   * Updates notifications
   * @param notif Takes UserNotification as object to create
   * @returns UserNotification
   */
  public update(notif: UserNotification[]): Observable<UserNotification[]> {
    const url = `${this.apiUrl}`;
    const req = this.http.post<UserNotification[]>(url, notif);

    return req;
  }

}
