
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import type { UserPrefs } from '../../user-preferences/shared/user-prefs';

/**
 * Service for controlling user data
 */
@Injectable()
export class UserPreferencesService {

  /** Sets language of app */
  public language = 'en';

  /** API Url */
  private readonly apiUrl = `${environment.apiBaseUrl}/user`;

  /**
   * Clears cache on login and logout and sets error context
   */
  public constructor(@Inject(HttpClient) private readonly http: HttpClient) {}

  /**
   * Adds a user to an organization
   * @param token Token attached to org
   * @returns void
   */
  public addUserToOrg(token: string): Observable<void> {
    const url = `${this.apiUrl}/invitations/${token}/accept`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const req = this.http.post(url, null, { headers }).pipe(map(() => undefined));

    return req;
  }

  /**
   * Updates Current User profile
   * @param user Takes User as object to create
   * @returns User
   */
  public updateUserPreferences(prefs: UserPrefs): Observable<UserPrefs> {
    const url = `${this.apiUrl}/preferences`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const req = this.http.put<UserPrefs>(url, prefs, { headers });

    return req;
  }

}
