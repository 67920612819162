/* eslint-disable max-lines */
import { Inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  from,
  of,
} from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import type { UserProfileDescription } from './user/user-profile/shared/user-profile';

interface UserPrefs {
  default_organization: {
    id: string;
    name: string;
    time_zone: string;
  };
  default_timezone: string;
  locale: string;
  date_format: string;
  use_24_hour_clock: boolean;
}

interface User {
  id: string;
  organization_identifier: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_registered_user?: boolean;
  email: string;
  middle_name: string;
  suffix?: string;
  prefix?: string;
  dob: string;
  gender: string;
  role: {
    id: string;
    name?: string;
  };
}

export const interceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private isSetup = false;

  private readonly isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);

  private memoryStorage: Record<string, string> = {};

  /** Observable for attempting logins */
  private readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);

  private readonly STOREKEYS = {
    USER_PREFERENCES: 'user_preferences_v4',
    USER_PROFILE: 'user_profile_v4',
    CURRENT_ORGANIZATION: 'current_organization_v4',
    ALLOWED_ORGANIZATIONS: 'allowed_organizations_v4',
  };

  private readonly useMemoryStore = false;

  public constructor(@Inject(OAuthService) private readonly oauthService: OAuthService,
    @Inject(Router) private readonly router: Router,
    @Inject(LocalStorageService) private readonly storageService: LocalStorageService,
    @Inject(HttpClient) private readonly http: HttpClient) {
    // this.clearCache();

    // external logout detection
    window.addEventListener('storage', (event) => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    // external event, recheck auth login status
    this.oauthService.events
      .subscribe((x) => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        if (x.type === 'session_terminated' || x.type === 'token_refresh_error') {
          this.logout();
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();


  // eslint-disable-next-line @typescript-eslint/member-ordering
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values: boolean[]) => values.every(b => b)));


  // eslint-disable-next-line max-lines-per-function
  public setup(): Observable<boolean> {
    this.oauthService.configure(environment.oauthConfig);
    // eslint-disable-next-line no-console
    console.log('setup');
    const task = this.oauthService
      .loadDiscoveryDocument()
      .then(async() => this.oauthService.tryLogin().catch((err) => {
      // eslint-disable-next-line no-console
        console.error('then1 catch', err);
      }))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('then2');
        this.oauthService.setupAutomaticSilentRefresh();
        return this.oauthService.hasValidAccessToken();
      })
      .then(async(isLoggedIn) => {
        // eslint-disable-next-line no-console
        console.log('then3');
        if (!isLoggedIn) {
          // eslint-disable-next-line no-console
          console.log('then3 !isLoggedIn');
          this.clearCache();
          if (this.oauthService.hasValidIdToken()) {
            // eslint-disable-next-line no-console
            console.log('then3 !isLoggedIn has valid id token');
            return this.oauthService.silentRefresh().then(() => this.oauthService.hasValidAccessToken());
          }

          return false;
        }

        return true;
      })
      .then((isLoggedIn) => {
        // eslint-disable-next-line no-console
        console.log('then4', `isloggedin: ${isLoggedIn}`);

        if (isLoggedIn) {
          // eslint-disable-next-line no-console
          console.log('then4 isloggedin');
          this.updateLocalUserData()
            .subscribe({
              error: () => {
                // eslint-disable-next-line no-console
                console.log('then4 isloggedin /me error');
                // silently fail
              },
            });
        }

        let stateUrl = this.oauthService.state;

        if (stateUrl && !stateUrl.startsWith('/')) {
          stateUrl = decodeURIComponent(stateUrl);
        }

        this.isSetup = true;
        this.isDoneLoadingSubject$.next(true);
        this.isAuthenticatedSubject$.next(isLoggedIn);

        if (stateUrl) {
          window.location.replace(`${window.location.origin}${stateUrl}`);
        }

        return isLoggedIn;
      });

    return from(task);
  }

  public isLoggedIn(): Observable<boolean> {
    if (this.isSetup) {
      return of(this.oauthService.hasValidIdToken());
    }

    return this.isDoneLoading$
      .pipe(take(1))
      .pipe(map(() => this.oauthService.hasValidAccessToken()));
  }

  public login(path?: string): void {
    this.isLoggedIn().subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate([path]);
      } else {
        this.oauthService.initCodeFlow(path);
      }
    });
  }

  public logout(path?: string): void {
    this.clearCache();
    if (path) {
      this.oauthService.logoutUrl = `${window.location.origin}/login?redirect=${path}`;
    }
    this.oauthService.revokeTokenAndLogout()
      .then(() => {
        // just trigger, do nothing after
      })
      .catch(() => {
        // todo: why do nothing?
      });
    this.isAuthenticatedSubject$.next(false);
  }

  public clearCache(): void {
    this.storageService.clear('current_organization');
    this.storageService.clear('user_preferences');
    this.storageService.clear('allowed_organizations');
    this.clearStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION);
    this.clearStoredValue(this.STOREKEYS.USER_PREFERENCES);
    this.clearStoredValue(this.STOREKEYS.ALLOWED_ORGANIZATIONS);
  }

  public getCurrentOrganization(): Observable<UserOrg> {
    const cachedOrg = this.getStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION);

    if (cachedOrg) {
      return of(JSON.parse(cachedOrg));
    }

    return forkJoin([
      this.getCurrentUserPrefs(),
      this.getAllowedOrganizations(),
    ]).pipe(map((res: [UserPrefs, UserOrg[]]) => res[1].find(x => x.id === res[0].default_organization.id)!),
      tap((dfltOrg) => {
        const str = JSON.stringify(dfltOrg);
        this.setStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION, str);
      }));
  }

  public getCurrentUserPrefs(): Observable<UserPrefs> {
    const cachedPrefs = this.getStoredValue(this.STOREKEYS.USER_PREFERENCES);

    /*
     * const headers = new HttpHeaders()
     *   .set('Content-Type', 'application/json')
     *   .set(interceptorSkipHeader, '');
     */

    if (cachedPrefs) {
      return of(JSON.parse(cachedPrefs));
    }

    const url = `${environment.apiBaseUrl}/user/preferences`;

    const headers = new HttpHeaders().append(interceptorSkipHeader, '');

    return this.http
      .get<UserPrefs>(url, { headers })
      .pipe(tap((prefs) => {
        this.setStoredValue(this.STOREKEYS.USER_PREFERENCES, JSON.stringify(prefs));
      }));
  }

  public getUserProfile(): Observable<UserProfileDescription> {
    const cachedPrefs = this.getStoredValue(this.STOREKEYS.USER_PROFILE);

    if (cachedPrefs) {
      return of(JSON.parse(cachedPrefs));
    }
    const url = `${environment.apiBaseUrl}/user/profile`;
    return this.http
      .get<UserProfileDescription>(url)
      .pipe(tap((prefs) => {
        this.setStoredValue(this.STOREKEYS.USER_PROFILE, JSON.stringify(prefs));
      }));
  }

  public userHasPermission(permissions: string[]): Observable<boolean> {
    if (permissions.length === 0) {
      return of(true);
    }

    return this.isLoggedIn().pipe(switchMap((isloggedIn) => {
      if (!isloggedIn) {
        return of(false);
      }
      return this.getCurrentOrganization().pipe(map((results: UserOrg) => {
        const org = results;
        let hasPermission = false;

        permissions.forEach((requiredPermission) => {
          if (requiredPermission.endsWith('*')) {
            const permissionprefix = requiredPermission.substring(-0,
              requiredPermission.length - 2);
            if (
              org.permissions.some(userPermission => userPermission.startsWith(permissionprefix))
            ) {
              hasPermission = true;
            }
          } else if (org.permissions.some(x => x === requiredPermission)) {
            hasPermission = true;
          }
        });

        return hasPermission;
      }));
    }));
  }

  public updateLocalUserData(): Observable<unknown> {
    const url = `${environment.apiBaseUrl}/user/profile`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(interceptorSkipHeader, '');
    const req = this.http.put<User>(url, null, { headers }).pipe(map(() => {
      // idr why we had void return type
    }));
    return req;
  }

  public getAllowedOrganizations(): Observable<UserOrg[]> {
    const cachedPrefs = this.getStoredValue(this.STOREKEYS.ALLOWED_ORGANIZATIONS);

    if (cachedPrefs) {
      return of(JSON.parse(cachedPrefs));
    }

    const url = `${environment.apiBaseUrl}/user/organizations`;

    return this.http
      .get<UserOrg[]>(url)
      .pipe(tap((prefs) => {
        this.setStoredValue(this.STOREKEYS.ALLOWED_ORGANIZATIONS, JSON.stringify(prefs));
      }));
  }

  public swapOrg(org: UserOrg): void {
    this.clearCache();
    const str = JSON.stringify(org);
    this.setStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION, str);
  }

  public getOauthAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public getUserInfo(): {
    first_name: string;
    last_name: string;
    id: string;
    email: string;
    phone: string;
    email_verified: boolean;
    phone_verified: boolean;
    iss: string;
  } {
    const claims: any = this.oauthService.getIdentityClaims();

    return {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      first_name: claims.given_name,
      last_name: claims.family_name,
      id: claims.sub,
      email: claims.email,
      phone: claims.phone_number,
      email_verified: claims.email_verified,
      phone_verified: claims.phone_number_verified,
      iss: claims.iss,
    };
  }
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */

  private setStoredValue(key: string, value: any): void {
    if (this.useMemoryStore) {
      this.memoryStorage[key] = value;
    } else {
      this.storageService.store(key, value);
    }
  }

  private getStoredValue(key: string): string {
    if (this.useMemoryStore) {
      return this.memoryStorage[key];
    }
    return this.storageService.retrieve(key);
  }

  private clearStoredValue(key: string): void {
    if (this.useMemoryStore) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.memoryStorage[key];
    } else {
      this.storageService.clear(key);
    }
  }

}

export interface UserOrg {
  id: string;
  name: string;
  time_zone: string;
  modules: {
    driver_behavior_enabled: boolean;
    avl_enabled: boolean;
    transit_enabled: boolean;
    sav_enabled: boolean;
    video_enabled: boolean;
    health_enabled: boolean;
    student_tracking_enabled: boolean;
    retention_period_months: number;
    live_view_enabled: boolean;
  };
  permissions: string[];
}
