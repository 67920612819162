import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { AlertMessage } from '..';

/**
 * Pipe for repeating alerts in ngFor
 */
@Pipe({ name: 'alertType' })
export class AlertTypePipe implements PipeTransform {

  /**
   * Transforms ngfor of alerts to display
   * @param allAlerts Takes array of alerts
   * @param msgType string message type danger/success/warning
   */
  public transform(allAlerts: AlertMessage[], msgType: string): AlertMessage[] {
    return allAlerts.filter(alert => alert.type === msgType);
  }

}
