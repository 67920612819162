<div [formGroup]="tgtFormGroup" *ngIf="tgtFormGroup" style="font-size: 12px" class=" bg-off-white">
        <div class="row filter-section-header">
            <div class="col-12" [translate]="'general.vehicle'"></div>
        </div>


        <div class="row">
            <div class="col-12" style="margin-top: 10px">
                <select class="form-select form-select-sm" [(ngModel)]="groupSelectedId" [ngModelOptions]="{standalone: true}" (ngModelChange)="onGroupChanged()">
                    <option value="" [translate]="'shared.any-group'"></option>
                    <option *ngFor="let g of groupOptions" [ngValue]="g.id">{{ g.name }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-12" style="margin-top: 10px;">  <!--<*ngIf="mode === 'SELECT' && multipleVehicles">-->
                <input type="text" class="form-control form-control-sm" [(ngModel)]="vehicleSearch"
                 [ngModelOptions]="{standalone: true}" placeholder="{{ 'shared.search-vehicles' | translate }}" autocomplete="off" />
            </div>
        </div>

        <div class="row filter-section-content">
            <div class="col-12">

                <span *ngIf="mode === 'SELECT'">
                    <select id="filter_vehicle_id" class="form-select form-select-sm" id="group-box-height" multiple *ngIf="multipleVehicles"
                     [(ngModel)]="selectedVehicles" [ngModelOptions]="{standalone: true}" (change)="vehicleSelectionChanged()">
                            <option value="" *ngIf="multipleVehicles" [translate]="'shared.any-vehicle'"></option>
                            <option *ngFor="let v of vehicleOptions | callback: filterVehicles" [value]="v.id">{{ v.name }}</option>
                    </select>

                    <select id="filter_vehicle_id" class="form-select form-select-sm" *ngIf="!multipleVehicles" [formControlName]="vehicleFormControlName" (change)="vehicleSelected.emit()">
                        <option value="" *ngIf="vehicleSelectionIsRequired" [translate]="'general.select-vehicle'"></option>
                        <option value="" *ngIf="!vehicleSelectionIsRequired" [translate]="'general.all-vehicles'"></option>

                        <option *ngFor="let v of vehicleOptions | callback: filterVehicles" [ngValue]="v.id">{{ v.name }}</option>
                    </select>
                </span>

                <span *ngIf="mode === 'CHECK'">
                    <div class="vehicleChecks" style="height: 200px; overflow-y: auto; overflow-x:hidden;">
                        <div class="row" *ngFor="let v of vehicleOptions | callback: filterVehicles">
                            <div class="offset-1 col-8">
                                {{ v.name }}
                            </div>
                            <div class="col-1">
                                <input type="checkbox" (click)="toggleSelectedVehicle(v.id)" [checked]="tgtFormGroup.value['vehicle.id'].indexOf(v.id) > -1" />
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>

    </div>
