<div class="modal-content" id="org-form-modal">
    <div class="modal-header">
        <span class="modal-title pull-left" [translate]="'shared.change-orgs'">
        </span>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="activeModal.dismiss('cancel')"></button>
    </div>

    <div class="modal-body">
        <div class="container">

            <div class="row">
                <div class="col-12">
                    <mtx-alert-display [alerts]="messages"></mtx-alert-display>
                </div>
            </div>


            <div class="row" style="margin-bottom: 10px;">
                <div *ngIf="loadingRequest" style="height: 250px;">
                    <mtx-loading-dialog [watch]="loadingRequest"></mtx-loading-dialog>
                </div>
                <div class="col-12">
                    <input type="text" #changeOrgInput placeholder="{{ 'shared.search-orgs' | translate }}" [(ngModel)]="orgSearch" [ngModelOptions]="{standalone: true}" style="margin-bottom: 10px;" class="form-control" />

                    <div *ngIf="(organizations?.length ?? 0) > 0; else noOrgs">
                      <div style="margin-bottom: 10px; font-weight: 600" >Current Organization: {{ currentOrg?.name }}</div>

                      <table class="table table-hover">
                        <tbody>
                          <tr *ngFor="let o of organizations | SearchByName: orgSearch">
                            <td class="org-link" style="cursor: pointer;" (click)="switchOrg(o)" [ngClass]="{ 'selected-org': o.id === currentOrg?.id }">{{ o.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                <ng-template #noOrgs>
                    <span *ngIf="!!!loadingRequest && messages?.length === 0" class="org-link d-flex flex-grow-1 justify-content-center" [translate]="'error-pages.no-orgs'"></span>
                </ng-template>
            </div>


        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="activeModal.dismiss('cancel')" [translate]="'general.cancel'"></button>
        </div>

    </div>
</div>
