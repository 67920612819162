<form [formGroup]="notifForm" (ngSubmit)="save()" [ngClass]="{ 'ng-submitted': notifForm.submitted === true }">
    <div class="modal-content" id="notification-form-modal">
        <div class="modal-header">
            <span class="modal-title pull-left" [translate]="'notifications.edit-notif'">
            </span>
            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            </button>
        </div>

        <div class="modal-body">
            <div class="container">

                <div class="row">
                    <div class="col-12">
                        <mtx-alert-display [alerts]="messages"></mtx-alert-display>
                    </div>
                </div>

                <div class="row mb-3">
                  <small class="text-danger text-center col-12 p-0" *ngIf="checkEmailNotifications() && !userInfo?.email_verified" [translate]="'notifications.email_verification_error'"></small>
                  <small class="text-danger text-center col-12 p-0" *ngIf="checkMobileNotifications() && !userInfo?.phone"><span [translate]="'notifications.phone_missing_error'"></span><a class="text-danger" target="_blank" href="https://accounts.angeltrax.com/realms/master/account/#/personal-info"><u [translate]="'general.here'"></u>.</a></small>
                  <small class="text-danger text-center col-12 p-0" *ngIf="checkMobileNotifications() &&userInfo?.phone && !userInfo?.phone_verified" [translate]="'notifications.phone_verification_error'"></small>

                    <div style="min-width: 50%; text-align: center; width: auto">
                        <h6>
                            {{userInfo?.email}}
                            <br/>
                        </h6>
                    </div>
                    <div class="col-4 text-center w-auto">
                        <h6>
                            {{userInfo?.phone}}
                            <br/>
                        </h6>
                    </div>
                </div>


                <div class="row">
                    <div class="col-lg-6">
                        <span [translate]="'admin.user-profile.camera-loss'"></span>

                        <span class="float-lg-end">
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="emailNotifs.indexOf('camera_loss') > -1" value="camera_loss" (change)="toggleEmailNotification('camera_loss')"
                                        class="form-check-input" />
                                    <i class="fa fa-envelope-o"></i>
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="mobileNotifs.indexOf('camera_loss') > -1" value="camera_loss" (change)="toggleMobileNotification('camera_loss')"
                                        class="form-check-input"  [disabled]="!mobileEnabled" />
                                    <i class="mtx-icon mtx-icon-tech-support"></i>
                                </label>
                            </div>
                        </span>

                    </div>
                    <div class="col-lg-6">
                      <span [translate]="'admin.user-profile.diskunable'"></span>

                      <span class="float-lg-end">
                          <div class="form-check-inline">
                              <label class="form-check-label">
                                  <input type="checkbox" [checked]="emailNotifs.indexOf('disk_unable') > -1" value="disk_unable" (change)="toggleEmailNotification('disk_unable')"
                                      class="form-check-input" />
                                  <i class="fa fa-envelope-o"></i>
                              </label>
                          </div>
                          <div class="form-check-inline">
                              <label class="form-check-label">
                                  <input type="checkbox" [checked]="mobileNotifs.indexOf('disk_unable') > -1" value="disk_unable" (change)="toggleMobileNotification('disk_unable')"
                                      class="form-check-input"  [disabled]="!mobileEnabled" />
                                  <i class="mtx-icon mtx-icon-tech-support"></i>
                              </label>
                          </div>
                      </span>
                  </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <span [translate]="'admin.user-profile.panic'"></span>

                        <span class="float-lg-end">
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="emailNotifs.indexOf('panic_event') > -1" value="panic_event" (change)="toggleEmailNotification('panic_event')"
                                        class="form-check-input" />
                                    <i class="fa fa-envelope-o"></i>
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="mobileNotifs.indexOf('panic_event') > -1" value="panic_event" (change)="toggleMobileNotification('panic_event')"
                                        class="form-check-input"  [disabled]="!mobileEnabled" />
                                    <i class="mtx-icon mtx-icon-tech-support"></i>
                                </label>
                            </div>
                        </span>
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <span [translate]="'admin.user-profile.geo-fence'"></span>

                        <span class="float-lg-end">
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="emailNotifs.indexOf('geo_fencing') > -1" value="geo_fencing" (change)="toggleEmailNotification('geo_fencing')"
                                        class="form-check-input" />
                                    <i class="fa fa-envelope-o"></i>
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="mobileNotifs.indexOf('geo_fencing') > -1" value="geo_fencing" (change)="toggleMobileNotification('geo_fencing')"
                                        class="form-check-input"  [disabled]="!mobileEnabled" />
                                    <i class="mtx-icon mtx-icon-tech-support"></i>
                                </label>
                            </div>
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <span [translate]="'admin.user-profile.blue-light'"></span>

                        <span class="float-lg-end">
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="emailNotifs.indexOf('blue_light') > -1" value="blue_light" (change)="toggleEmailNotification('blue_light')"
                                        class="form-check-input" />
                                    <i class="fa fa-envelope-o"></i>
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="checkbox" [checked]="mobileNotifs.indexOf('blue_light') > -1" value="blue_light" (change)="toggleMobileNotification('blue_light')"
                                        class="form-check-input"  [disabled]="!mobileEnabled" />
                                    <i class="mtx-icon mtx-icon-tech-support"></i>
                                </label>
                            </div>
                        </span>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button type="submit" class="btn btn-default btn-submit" value="Submit" id="save-btn" [translate]="'general.submit'"></button>
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="activeModal.dismiss('cancel')" [translate]="'general.close'"></button>
            </div>

        </div>
    </div>
</form>
