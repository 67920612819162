import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../auth-guard.service';
import { UserJoinOrgComponent } from './user-join-org/user-join-org.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';

export const routes: Routes = [
  {
    path: 'users',
    children: [
      { path: '',
        pathMatch: 'full',
        redirectTo: 'preferences' },
      {
        path: 'preferences',
        component: UserPreferencesComponent,
        canActivate: [AuthGuard],
      },
      { path: 'join/:token',
        component: UserJoinOrgComponent },
      { path: 'join',
        component: UserJoinOrgComponent },
    ],
  },
];

/**
 * Module for login routing
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
